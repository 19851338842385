import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "components/ui/loader";
import eventType from "types/event.type";

/** Kneo Game Last results Component */
const KenoLastResults = ({ lastResults, isLoading }) => {
	const { t } = useTranslation();

	const scenes = event?.gameData?.scenes ?? [];

	return (
		<div className="vs--keno-last-results">
			{!isLoading ? (
				<div className="vs--keno-last-results-inner vs--flex vs--align-center">
					<div className="vs--keno-last-results-title vs--flex vs--align-center vs--justify-center vs--pl-24 vs--pr-24">
						<b className="vs--title vs--font-medium">{t("common.last5Games")}</b>
					</div>
					<div className={"vs--keno-last-results-row vs--keno-last-results-row-animating"}>
						{lastResults.map((event) => (
							<div className="vs--keno-last-results-row-item vs--flex vs--align-center vs--pl-24 vs--pr-24" key={event.id}>
								<b className="vs--title vs--font-medium vs--pr-16"># {event.id}</b>
								<div className="vs--keno-last-results-row-item-list vs--flex vs--align-center">
									{scenes
										.filter((sc) => sc.isCompleted)
										.map((ball) => (
											<div className="vs--keno-last-results-row-item-list-ball vs--flex vs--align-center vs--justify-center vs--mr-8" key={ball.number}>
												<div className="vs--keno-last-results-row-item-list-ball-inner vs--flex vs--align-center vs--justify-center">
													<span className="vs--title vs--font-medium">{ball.number === 6 || ball.number === 9 ? `${ball.number}.` : ball.number}</span>
												</div>
											</div>
										))}
								</div>
							</div>
						))}
						{lastResults.map((event) => (
							<div className="vs--keno-last-results-row-item vs--flex vs--align-center vs--pl-24 vs--pr-24" key={event.id}>
								<b className="vs--title vs--font-medium vs--pr-16"># {event.id}</b>
								<div className="vs--keno-last-results-row-item-list vs--flex vs--align-center">
									{scenes
										.filter((sc) => sc.isCompleted)
										.map((ball) => (
											<div className="vs--keno-last-results-row-item-list-ball vs--flex vs--align-center vs--justify-center vs--mr-8" key={ball.number}>
												<div className="vs--keno-last-results-row-item-list-ball-inner vs--flex vs--align-center vs--justify-center">
													<span className="vs--title vs--font-medium">{ball.number === 6 || ball.number === 9 ? `${ball.number}.` : ball.number}</span>
												</div>
											</div>
										))}
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<Loader />
			)}
		</div>
	);
};

/** KenoLastResults propTypes
 * PropTypes
 */
KenoLastResults.propTypes = {
	/** Redux state property, the array of last results */
	lastResults: PropTypes.arrayOf(eventType),
	/** Redux state property, is true when last results are loading */
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		lastResults: state.game.lastResults.data,
		isLoading: state.game.lastResults.loading
	};
};

export default connect(mapStateToProps, null)(KenoLastResults);
