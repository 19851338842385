import  { Fragment, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { numberWithSpaces, toFixed, multiPrintElement } from "utils/common";
import { setReportPrinting } from "store/actions/report/report.actions";
import { logout } from "store/actions/auth/auth.actions";
import { REPORT_TYPE, TURNOVER_TYPE } from "constants/common.constants";
import { GAME_TYPE_TEXTS_BY_NAME } from "constants/game.constants";
import sessionType from "types/session.type";
import turnoverType from "types/turnover.type";

/** Report print Component */
const Report = ({ session, turnover, hideReportPrinting, printingReport, logout }) => {
	const { t } = useTranslation(["ticket"]);

	const allowPrintBonusData = useMemo(() => Object.keys(turnover).some((key) => key.includes("bonus") && turnover[key] !== 0), [turnover]);
	const turnoverReportTypes = useMemo(() => {
		const types = [TURNOVER_TYPE.SIMPLE];
		if (allowPrintBonusData) {
			types.push(TURNOVER_TYPE.BONUS);
		}
		return types;
	}, [allowPrintBonusData]);

	/** Print Report */
	useEffect(() => {
		multiPrintElement(
			turnoverReportTypes.map((trt) => `vs--print-wrapper-${trt}`),
			() => {
				setTimeout(() => {
					hideReportPrinting();
					if (printingReport === REPORT_TYPE.SETTLEMENT) {
						logout();
					}
				}, 0);
			}
		);
	}, [turnoverReportTypes, printingReport]);

	/** Function to get respective value for respective game type and field from turnover report
	 * @function
	 * @param {string} gameType - game type or total
	 * @param {string} reportField - the field name
	 * @returns {number}
	 * @memberOf Report
	 */
	const getColValue = (gameType, reportField) => {
		if (gameType !== "total") {
			const res = turnover?.report?.[gameType]?.[reportField] ?? "";
			if (res !== "") {
				if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"].includes(reportField)) {
					return res;
				} else {
					return numberWithSpaces(toFixed(res, session?.currency?.decimalCount ?? 0));
				}
			} else {
				return "";
			}
		} else {
			const res = turnover[reportField] !== undefined ? turnover[reportField] : "";

			if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"].includes(reportField)) {
				return res;
			} else {
				return numberWithSpaces(toFixed(res, session?.currency?.decimalCount ?? 0));
			}
		}
	};

	/** Table Cols data */
	const tableCols = useCallback((argType) => {
		switch (argType) {
			case TURNOVER_TYPE.SIMPLE:
				return [
					[
						{
							key: "turnover",
							title: t("cashier.ticketTurnover")
						},
						{
							key: "canceled",
							title: t("cashier.ticketCanceled")
						},
						{
							key: "netTurnover",
							title: t("cashier.ticketNetTurover")
						}
					],
					[
						{
							key: "won",
							title: t("cashier.ticketWon")
						},
						{
							key: "payout",
							title: t("cashier.ticketPayout")
						},
						{
							key: "wonUnpaid",
							title: t("cashier.ticketNotPaid")
						}
					],
					[
						{
							key: "betSlipCount",
							title: t("cashier.ticketBetslipsCount")
						},
						{
							key: "playedCount",
							title: t("cashier.ticketPlayedCount")
						},
						{
							key: "wonCount",
							title: t("cashier.ticketWonCount")
						}
					]
				];
			case TURNOVER_TYPE.BONUS:
				return [
					[
						{
							key: "bonusTurnover",
							title: t("bonus.bonus") + " " + t("cashier.ticketTurnover")
						},
						{
							key: "bonusGGR",
							title: t("bonus.bonus") + " " + t("cashier.ggr")
						}
					],
					[
						{
							key: "bonusWon",
							title: t("bonus.bonus") + " " + t("cashier.ticketWon")
						},
						{
							key: "bonusPayout",
							title: t("bonus.bonus") + " " + t("cashier.ticketPayout")
						},
						{
							key: "bonusWonUnpaid",
							title: t("bonus.bonus") + " " + t("cashier.ticketNotPaid")
						}
					],
					[
						{
							key: "bonusBetSlipsCount",
							title: t("bonus.bonus") + " " + t("cashier.ticketBetslipsCount")
						},
						{
							key: "bonusWonCount",
							title: t("bonus.bonus") + " " + t("cashier.ticketWonCount")
						}
					]
				];
			default:
				break;
		}
		return [];
	}, []);

	return (
		<Fragment>
			{turnoverReportTypes.map((trt) => {
				return (
					<div key={trt} id={`vs--print-wrapper-${trt}`} className="vs--print-wrapper">
						<div className="vs--print" key={trt}>
							{session.betTicket.ticketLogoId ? (
								<div className="vs--print-header">
									<img src={`${import.meta.env.SYSTEM_CDN_URL}/${session.partnerId}/images/${session.betTicket.ticketLogoId}_ticket_logo.png`} alt="logo" />
								</div>
							) : null}
							<div className="vs--print-header-sub vs--print-section">
								<div className="vs--print-header-sub-title">
									<i className="ic_virtualSports" />
									<h1>{t("common.virtualSports")}</h1>
								</div>
								<h2>{printingReport === REPORT_TYPE.INTERIM ? t("cashier.interimReport") : t("cashier.settlementReport")}</h2>
							</div>
							<div className="vs--print-info vs--print-info-dotted vs--print-section">
								<div className="vs--print-info-row">
									<span>{t("cashier.betshop")}</span>
									<span>{session.projectName}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.address")}</span>
									<span>{session.address}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.printed")}</span>
									<span>{moment.utc().local().format(DATE_TIME_FORMAT)}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.cashier")}</span>
									<span>{session?.cashier?.userName}</span>
								</div>
								<div className="vs--print-info-row">
									<span>{t("cashier.currency")}</span>
									<span>{session?.currency?.code}</span>
								</div>
							</div>

							{tableCols(trt).map((group) => (
								<div className="vs--print-info vs--print-info-dotted vs--print-section" key={group[0].key}>
									<div className="vs--print-table">
										<div className="vs--print-table-row vs--print-table-row-head vs--mb-4">
											<div className="vs--print-table-cell"></div>
											{group.map((item) => (
												<div className="vs--print-table-cell" key={item.key}>
													<span>{item.title}</span>
												</div>
											))}
										</div>
										{Object.keys(turnover.report)
											.concat(Object.keys(turnover.report).length > 0 ? ["total"] : [])
											.map((game) => (
												<div className="vs--print-table-row" key={game} data-game={game}>
													<div className="vs--print-table-cell">
														<span>{GAME_TYPE_TEXTS_BY_NAME[game] ? t(GAME_TYPE_TEXTS_BY_NAME[game]) : ""}</span>
													</div>
													{group.map((item) => (
														<div className="vs--print-table-cell" key={item.key}>
															<span>{getColValue(game, item.key)}</span>
														</div>
													))}
												</div>
											))}
									</div>
								</div>
							))}

							<div className="vs--print-info vs--print-info-dotted vs--print-section">
								<div className="vs--print-info-row">
									<span>{(trt === TURNOVER_TYPE.BONUS ? t("bonus.bonus") + " " : "") + t("cashier.ggr")}</span>
									<span>{getColValue("total", trt === TURNOVER_TYPE.SIMPLE ? "realGGR" : "bonusGGR")}</span>
								</div>
							</div>
							{trt === TURNOVER_TYPE.SIMPLE ? (
								<div className="vs--print-info vs--print-info-dotted vs--print-section">
									<div className="vs--print-info-row">
										<span>{t("cashier.finalBalance")}</span>
										<span>{getColValue("total", "balance")}</span>
									</div>
								</div>
							) : null}
							<div className="vs--print-signature">
								<div>
									<span>{t("cashier.cashier")}:</span>
								</div>
								<div>
									<span>{t("cashier.manager")}:</span>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</Fragment>
	);
};

/** Report propTypes
 * PropTypes
 */
Report.propTypes = {
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, the turnover report */
	turnover: turnoverType,
	/** Redux action to hide report print component*/
	hideReportPrinting: PropTypes.func,
	/** Redux state property, printing report type */
	printingReport: PropTypes.oneOf(Object.values(REPORT_TYPE)),
	/** Redux action to logout */
	logout: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		turnover: state.report.turnover,
		printingReport: state.report.printingReport
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideReportPrinting: () => {
		dispatch(setReportPrinting(null));
	},
	logout: () => {
		dispatch(logout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
