import i18n from "translations/config";

import RacingSportMarkets from "./racingSportMarkets";

class SteeplechaseSportMarkets extends RacingSportMarkets {
	

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof SteeplechaseSportMarkets
	 */
	getGroupsStructure() {
		
		return {
			main: [
				{ group: "Winner", title: "Winner", outcomes: "*", marketsCount: 12, type: "race" },
				{ group: "Place", title: "Place", outcomes: "*", marketsCount: 12, type: "race" },
				{ group: "Show", title: "Show", outcomes: "*", marketsCount: 12, type: "race" },
				{ group: "Pair", title: "Pair", outcomes: "*", type: "race" },
				{ group: "Triple", title: "Triple", outcomes: "*", type: "race" }
			],
			extra: [
				{ group: "WinnerNumber", title: "WinnerNumber", outcomes: ["Over", "Under"], marketsCount: 7, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: "Total123Placed", title: "Total123Placed", outcomes: ["Over", "Under"], marketsCount: 7, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: "RaceEvenOdd", title: "RaceEvenOdd", outcomes: ["Even", "Odd"], marketsCount: 1, marketCol: 2, gridBy: "row", oddBy: "col", argument: null },
				{ group: "WillNonFinishers", title: "WillNonFinishers", outcomes: ["Yes", "No"], marketsCount: 1, marketCol: 2, gridBy: "col", oddBy: "row", argument: null },
				{ group: "TotalNonFinishers", title: "TotalNonFinishers", outcomes: ["Over", "Under"], marketsCount: 3, marketCol: 3, gridBy: "col", oddBy: "row", argument: null },
			]
		};
	}

	/** Get market name from systemName
	 * @function
	 * @param {array } marketName - the market name
	 * @param {object} gameData - Event data
	 * @param {number} argument - the market argument
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	getNameForMarketGroup(marketName, outcome, argument) {
		if (["WinnerNumber", "TotalNonFinishers", "Total123Placed"].includes(marketName)) {
			return "%" + outcome + "% (" + argument + ")";
		} else if (["RaceEvenOdd"].includes(marketName)) {
			return "%" + outcome + "%";
		} else {
			return outcome;
		}
	}
}

export default SteeplechaseSportMarkets;
