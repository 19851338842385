import { PERIOD } from "constants/market.constants";

class BaseMarkets {
	constructor() {
		this.overUnderArgumentPriority = [2.5, 1.5, 3.5, 2, 1, 3, 0.5, 4, 4.5, 5, 5.5]
	}

	/** Get tabs for markets
		* @function
		* @returns {array}
		* @memberof BaseMarkets
	*/
	getMarketsTabs(roundOrderNumber = null) {
		return Object.keys(this.getGroupsStructure({ roundOrderNumber }))
	}

	/** Transform markets array to the useful array for markets UI , for all the tabs
	 * @function
	 * @param {array } markets - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - Current game type
	 * @returns {object}
	 * @memberof BaseMarkets
	 */
	makeAllMarkets(markets, gameData, rtps, gameType, roundOrderNumber) {
		const groups = this.getGroupsStructure({ roundOrderNumber });
		const result = {};
		try {
			Object.keys(groups).forEach(gr => {
				const filtered = this.filterMarkets(gr, markets, roundOrderNumber)
				result[gr] = this.makeMarketsArray(filtered, gameData, rtps, gameType)
			})
		} catch (ex) {
			console.log(ex)
		}
		return result;
	}

	/** Get All possible markets for tab
	 * @function
	 * @param {string } market - tab name
	 * @param {array} markets - markets to filter
	 * @returns {array}
	 * @memberof BaseMarkets
	 */
	filterMarkets(tab, markets, roundOrderNumber = null) {
		let filtered = [];
		if (!Array.isArray(markets)) {
			return filtered;
		}
		const allMarkets = this.defineArgumentForOverUnderGroup(this.getGroupsStructure({ roundOrderNumber })[tab], markets);
		allMarkets.forEach((m) => {
			const matching = markets.filter(
				(mrkt) => (mrkt.period === PERIOD.MATCH || mrkt.period === PERIOD.NONE) && ((typeof m.group === "function" && m.group(mrkt.group)) || mrkt.group === m.group) && (!m.argument || m.argument === mrkt.argument || (typeof m.argument === "function" && m.argument(mrkt.argument)))
			);
			filtered.push({
				...m,
				matching: matching
			})
		});

		filtered = filtered.map((gr) => {
			const marketsCount = gr.marketsCount || 1;
			if (gr.matching.length > marketsCount) {
				let newMatching = gr.matching;
				if (["Total", "HomeTotal", "AwayTotal", "RoundTotal"].includes(gr.group)) {
					newMatching = [...gr.matching].sort((a, b) => this.overUnderArgumentPriority.indexOf(a.argument) > this.overUnderArgumentPriority.indexOf(b.argument) ? 1 : -1).slice(0, marketsCount);
				} else if (["Handicap"].includes(gr.group)) {
					newMatching = [...gr.matching].sort((a, b) => a.argument > b.argument ? 1 : -1).slice(Math.floor((gr.matching.length - marketsCount) / 2), ((gr.matching.length - marketsCount) / 2) + marketsCount);
				}
				return {
					...gr,
					matching: newMatching
				}
			}
			return { ...gr };
		})
		return filtered;
	}

	/** Transform markets array to the array , useful for markets UI
	 * @function
	 * @param {array } data - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - Current game type
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		data.forEach((d) => {
			const key = typeof d.argument === "number" ? d.title + "_" + d.argument : d.title;
			obj[key] = {
				group: d.group,
				odds: Array(d.marketsCount > d.matching.length ? d.matching.length : d.marketsCount).fill(d.outcomes !== "*" ? d.outcomes : (d.matching[0] ? d.matching[0].oddList : []).map(odd => odd.outcome)),
				matching: d.matching,
				marketCol: d.marketCol,
				marketsCount: d.marketsCount,
				type: d.type,
				argument: d.argument,
				gridBy: d.gridBy,
				oddBy: d.oddBy
			}
		})

		Object.keys(obj).forEach((o) => {
			obj[o].oddsCopy = obj[o].odds.map((m) => m.slice());
			for (let index = 0; index < obj[o].oddsCopy.length; index++) {
				const groupName = o.split("_")[0];
				const matchingOdd = obj[o].matching[index];
				if (matchingOdd) {
					for (let a = 0; a < matchingOdd.oddList.length; a++) {
						const odd = matchingOdd.oddList[a];
						if (odd) {
							const i = obj[o].oddsCopy[index].indexOf(odd.outcome);
							obj[o].odds[index] = [...obj[o].odds[index]];
							obj[o].odds[index][i] = {
								name: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument),
								factor: this.applyRtp(
									odd.factor,
									["AsianTotal", "AsianHomeTotal", "AsianAwayTotal", "AsianHandicap"].includes(o) ? o : obj[o].group,
									matchingOdd.period,
									matchingOdd.argument,
									odd.outcome,
									rtps
								),
								oddState: odd.state,
								outcome: odd.outcome,
								argument: matchingOdd.argument,
								id: odd.id,
								showName: this.makeName(this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument), gameData, matchingOdd.argument, gameType),
								ticketShowName: this.makeName(this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument), gameData, matchingOdd.argument, gameType, true),
								rectData: this.getRectData(this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument), gameData, gameType),
							}
						}
					}
				}
			}
			delete obj[o].matching;
			delete obj[o].oddsCopy;
		});

		const arr = [];
		Object.keys(obj).forEach((o) => {
			let item = {
				group: o.split("_")[0],
				argument: o.split("_")[1],
				marketCol: obj[o].marketCol,
				marketsCount: obj[o].marketsCount,
				odds: obj[o].odds.map(oddGroup => oddGroup.map(odd => typeof odd === "string" && !["Total", "AsianTotal", "HomeTotal", "AsianHomeTotal", "AwayTotal", "AsianAwayTotal", "Handicap", "AsianHandicap", "RoundTotal"].includes(o.split("_")[0]) ? {
					name: this.getNameForMarketGroup(o.split("_")[0], odd),
					showName: this.makeName(this.getNameForMarketGroup(o.split("_")[0], odd), gameData, o.split("_")[1], gameType),
					ticketShowName: this.makeName(this.getNameForMarketGroup(o.split("_")[0], odd), gameData, o.split("_")[1], gameType, true)
				} : odd)),
				type: obj[o].type,
				gridBy: obj[o].gridBy,
				oddBy: obj[o].oddBy
			};
			item.odds = item.odds.map((oddGroup) => oddGroup.filter((odd) => typeof odd !== "string"));
			arr.push(item);
		});
		return arr;
	}

	/** Round factor
	 * @function
	 * @param {number} factor - factor
	 * @returns {number}
	 * @memberof BaseMarkets
	 */
	roundFactor(factor) {
		const roundedFactor = (
			factor < 1.01
				? factor
				: factor - 500 >= 0
					? Math.trunc(factor / 10) * 10
					: factor - 50 >= 0
						? Math.trunc(factor / 5) * 5
						: factor - 20 >= 0
							? Math.trunc(factor)
							: factor - 15 >= 0
								? (Math.trunc((factor * 10) / 5) * 5) / 10
								: factor - 7 >= 0
									? Math.round(factor * 10) / 10
									: factor - 3 >= 0
										? (Math.floor(((factor + 0.025) * 100) / 5) * 5) / 100
										: Math.round(factor * 100) / 100
		);
		return Math.min(roundedFactor, 1000);
	}

	/** Calculate factor using rtps
		 * @function
		 * @param {number} factor - factor
		 * @param {string} market - market name in system
		 * @param {string} period - market period
		 * @param {Array} rtps - rtps to apply
		 * @returns {number}
		 * @memberof BaseMarkets
 */
	applyRtp(factor, market, period, argument, outcome, rtps) {

		const marketPeriod = period || period.MATCH;
		let rtp = rtps.find((i) => i.name === market && i.period === period);

		if (market === "Handicap" && ((argument === -0.5 && outcome === "Home") || (argument === 0.5 && outcome === "Away"))) {
			rtp = rtps.find(i => i.name === "Winner3Way" && i.period === period);
		} else if (
			(market === "WillHomeTeamScore" && outcome === "No") ||
			(market === "Correct6HomeGoalsCount" && outcome === "Goal0")
		) {
			rtp = rtps.find(i => i.name === "HomeTotal" && i.period === period);
		} else if (
			(market === "WillAwayTeamScore" && outcome === "No") ||
			(market === "Correct6AwayGoalsCount" && outcome === "Goal0")
		) {
			rtp = rtps.find(i => i.name === "AwayTotal" && i.period === period);
		} else if (
			(market === "Correct6GoalsCount" && outcome === "Goal0") ||
			(market === "CorrectScore6Goals" && outcome === "Home0Away0") ||
			(market === "WinnerAndTotal" && (argument === 1.5 || argument === 0.5) && outcome === "DrawAndUnder") ||
			(market === "Total3Way" && argument === 1 && outcome === "Under") ||
			(market === "DoubleChanceAndTotal" && argument === 0.5 && (outcome === "HomeOrDrawAndUnder" || outcome === "AwayOrDrawAndUnder")) ||
			(market === "NextPoint" && argument === 0 && outcome === "Neither")
		) {
			rtp = rtps.find(i => i.name === "Total" && i.period === period);
		} else if (
			(market === "Correct6GoalsCount" && outcome === "Goal6" && period === PERIOD.MATCH) ||
			(market === "Total3Way" && argument === 5 && outcome === "Over")
		) {
			rtp = rtps.find(i => i.name === "Total" && i.period === period);
		} else if (market === "CorrectScore6Goals" && (outcome === "Home3Away0" || outcome === "Home0Away3") && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF)) {
			rtp = rtps.find(i => i.name === "Handicap" && i.period === period);
		} else if (
			(market === "Correct6GoalsCount" && outcome === "Goal3" && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF)) ||
			(market === "Total3Way" && argument === 2 && outcome === "Over" && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF))
		) {
			rtp = rtps.find(i => i.name === "Total" && i.period === period);
		} else if (market === "CorrectScore6Goals" && (outcome === "Home1Away0" || outcome === "Home0Away1")) {
			rtp = rtps.find(i => i.name === "WinnerAndTotal" && i.period === period);
		} else if (market === "CorrectScore6Goals" && outcome === "Home3Away3" && period === PERIOD.MATCH) {
			rtp = rtps.find(i => i.name === "WinnerAndTotal" && i.period === period);
		} else if (market === "CorrectScore6Goals" && outcome === "Home1Away1" && (period === PERIOD.FIRSTHALF || period === PERIOD.SECONDHALF)) {
			rtp = rtps.find(i => i.name === "WinnerAndTotal" && i.period === period);
		}

		const margin = rtp ? rtp.margin : 7.5;
		let newFactor = factor;
		newFactor *= 1 - (margin / 100);

		return this.roundFactor(newFactor);
	}

	/** Define arguments for groups related with over/under depend on markets which exists 
		* @function
		* @param {array} allMarkets - possible markets
		* @param {array} markets - match markets
		* @returns {array} - possible markets with correct arguments
		* @memberof BaseMarkets
	*/
	defineArgumentForOverUnderGroup(allMarkets, markets) {
		return allMarkets.map((m) => {
			let argument = null;
			if (m.argument !== "overUnder") return m;
			const matchingArguments = markets.filter((mrkt) => (mrkt.period === PERIOD.MATCH || mrkt.period === PERIOD.NONE) && mrkt.group === m.group).map((mrkt) => mrkt.argument);
			matchingArguments.sort((a, b) => (this.overUnderArgumentPriority.indexOf(a) > this.overUnderArgumentPriority.indexOf(b) ? 1 : -1));
			if (matchingArguments[m.groupOrder]) {
				argument = matchingArguments[m.groupOrder];
			} else {
				argument = this.overUnderArgumentPriority.filter(a => !matchingArguments.includes(a))[m.groupOrder - matchingArguments.length]
			}
			return { ...m, argument: argument };
		});
	}

	/** Abstract Methods */
	getGroupsStructure() {
		return {};
	}
	makeName() {
		return "";
	}
	makeWinnerMarketName() {
		return "";
	}
	makeGroupTitle() {
		return "";
	}
	makeBetText() {
		return "";
	}
	makeOddText() {
		return "";
	}
	getRectData() {
		return [];
	}
}

export default BaseMarkets;
