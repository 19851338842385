import  { Fragment, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { numberWithSpaces, toFixed } from "utils/common";
import Loader from "components/ui/loader";
import { GAME_TYPE_TEXTS_BY_NAME } from "constants/game.constants";
import { TURNOVER_TYPE } from "constants/common.constants";
import turnoverType from "types/turnover.type";
import sessionType from "types/session.type";

/** Reports Modal Turnover Tab Content Component */
const Turnover = ({ turnover, isLoading, session, type }) => {
	const { t } = useTranslation();

	/** Function to get respective value for respective game type and field from turnover report
	 * @function
	 * @param {string} gameType - game type or total
	 * @param {string} reportField - the field name
	 * @returns {number}
	 * @memberOf Turnover
	 */
	const getColValue = useCallback(
		(gameType, reportField) => {
			const decimalCount = session?.currency?.decimalCount ?? 0;

			if (gameType !== "total") {
				const res = turnover?.report?.[gameType]?.[reportField] ?? "";
				if (res !== "") {
					if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"].includes(reportField)) {
						return res;
					} else {
						return numberWithSpaces(toFixed(res, decimalCount));
					}
				} else {
					return "";
				}
			} else {
				const res = turnover[reportField] !== undefined ? turnover[reportField] : "";
				if (["betSlipCount", "playedCount", "wonCount", "bonusBetSlipsCount", "bonusWonCount"].includes(reportField)) {
					return res;
				} else {
					return numberWithSpaces(toFixed(res, decimalCount));
				}
			}
		},
		[turnover]
	);

	/** Table Rows data */
	const tableRows = useMemo(() => {
		switch (type) {
			case TURNOVER_TYPE.SIMPLE:
				return [
					{
						key: "turnover",
						title: t("cashier.turnover")
					},
					{
						key: "canceled",
						title: t("cashier.cancel")
					},
					{
						key: "netTurnover",
						title: t("cashier.netTurnover")
					},
					{
						key: "won",
						title: t("cashier.won")
					},
					{
						key: "payout",
						title: t("cashier.payout")
					},
					{
						key: "wonUnpaid",
						title: t("cashier.wonNotClaimed")
					},
					{
						key: "betSlipCount",
						title: t("cashier.betsCount")
					},
					{
						key: "playedCount",
						title: t("cashier.playedBetsCount")
					},
					{
						key: "wonCount",
						title: t("cashier.wonBetsCount")
					},
					{
						key: "realGGR",
						title: t("cashier.ggr"),
						isSummery: true
					},
					{
						key: "balance",
						title: t("cashier.finalBalance"),
						isSummery: true
					}
				];
			case TURNOVER_TYPE.BONUS:
				return [
					{
						key: "bonusTurnover",
						title: t("bonus.bonus") + " " + t("cashier.turnover")
					},
					{
						key: "bonusGGR",
						title: t("bonus.bonus") + " " + t("cashier.ggr"),
						isSummery: true
					},
					{
						key: "bonusWon",
						title: t("bonus.bonus") + " " + t("cashier.won")
					},
					{
						key: "bonusPayout",
						title: t("bonus.bonus") + " " + t("cashier.payout")
					},
					{
						key: "bonusWonUnpaid",
						title: t("bonus.bonus") + " " + t("cashier.wonNotClaimed")
					},
					{
						key: "bonusBetSlipsCount",
						title: t("bonus.bonus") + " " + t("cashier.betsCount")
					},
					{
						key: "bonusWonCount",
						title: t("bonus.bonus") + " " + t("cashier.wonBetsCount")
					}
				];

			default:
				break;
		}
		return [];
	}, [type]);

	const renderBlock = useCallback((text, withTitle, key) => {
		return (
			<div key={key}>
				<span className={`${key === 0 ? "vs--modal-table-head" : "vs--modal-table-row"} vs--flex vs--flex-row vs--align-center vs--justify-between vs--title vs--font-regular vs--font-exstrasmall`} title={withTitle ? text : null}>
					{text}
				</span>
			</div>
		);
	}, []);

	const content = useMemo(() => {
		const classes = [" vs--report-table-container-fields", " vs--report-table-container-games", " vs--report-table-container-total"];
		const structure = [null, [...Object.keys(turnover.report)], "total"].reduce(
			(acc, colGroups, i) => {
				if (!Array.isArray(colGroups)) {
					if (colGroups === null) {
						acc[i].push([renderBlock(" ", false, 0), ...tableRows.map((t) => renderBlock(t.title, true, t.key))]);
					} else if (typeof colGroups === "string") {
						acc[i].push([renderBlock(t(GAME_TYPE_TEXTS_BY_NAME[colGroups]), false, 0), ...tableRows.map((t) => renderBlock(getColValue(colGroups, t.key), true, t.key))]);
					}
					return acc;
				}
				acc[i] = acc[i].concat(
					colGroups.map((group) => {
						return (
							<div key={group} className="vs--flex vs--flex-col vs--flex-equal">
								{[renderBlock(t(GAME_TYPE_TEXTS_BY_NAME[group]), false, 0), ...tableRows.map((row) => renderBlock(getColValue(group, row.key), true, row.key))]}
							</div>
						);
					})
				);
				return acc;
			},
			[[], [], []]
		);
		return structure.map((elem, i) => {
			return (
				<div key={i} className={"vs--flex" + classes[i] + (i % 2 !== 0 ? " vs--flex-row vs--flex-equal" : " vs--flex-col")}>
					{elem}
				</div>
			);
		});
	}, [turnover, getColValue, renderBlock, tableRows]);

	return !isLoading ? (
		<div className="vs--modal-wrapper vs--flex-equal">
			<div className="vs--modal-table vs--flex vs--flex-row vs--report-table vs--flex-equal">
				{content}
				{/* <div className="vs--modal-table-head vs--flex vs--flex-row vs--align-center vs--justify-between vs--title vs--font-medium vs--font-small">
                    <div></div>
                    {
                        Object.keys(turnover.report).concat(Object.keys(turnover.report).length > 0 ? ["total"] : []).map(game => (
                            <div key={game}>
                                <span>
                                    {t(GAME_TYPE_TEXTS_BY_NAME[game] || "")}
                                </span>
                            </div>
                        ))
                    }
                </div>
                <div className="vs--modal-table-body vs--flex-equal vs--flex">
                    {
                        tableRows.reduce((acc, row, index) => {
                            const className = "vs--modal-table-row vs--flex vs--flex-row vs--align-center vs--justify-between vs--title vs--font-regular" + (index !== 0 ? " vs--mt-2" : "") + (row.isSummery ? " vs--modal-table-row-selected" : "")
                            acc[0].push(
                                <div
                                    key={row.key}
                                    className={className}
                                >
                                    <span title={row.title}>
                                        {row.title}
                                    </span>
                                </div>
                            )
                            acc[1].push(
                                <div
                                    key={row.key}
                                    className={"vs--flex vs--flex-row"}
                                >
                                    {
                                        Object.keys(turnover.report).map(game => {
                                            const text = getColValue(game, row.key)
                                            return (
                                                <div
                                                    key={game}
                                                    className={className}
                                                >
                                                    <span title={text}>
                                                        {text}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                            if (Object.keys(turnover.report).length > 0) {
                                const totalText = getColValue("total", row.key)
                                acc[2].push(
                                    <div
                                        key={row.key}
                                        className={className}
                                    >
                                        <span title={totalText}>
                                            {
                                                totalText
                                            }
                                        </span>
                                    </div>
                                )
                            }
                            
                            return acc
                        }, [[], [], []]).map((elem, i) => {
                            return (
                                <div
                                    key={i}
                                    className={"vs--flex vs--flex-col" + (i % 2 !== 0 ? " vs--flex-equal": "")}
                                >
                                    {
                                        elem
                                    }
                                </div>
                            )
                        })
                    }
                </div> */}
			</div>
		</div>
	) : (
		<Loader style={{ height: "100%" }} />
	);
};

/** Turnover propTypes
 * PropTypes
 */
Turnover.propTypes = {
	/** Redux state property, current session */
	turnover: turnoverType,
	/** Redux state property, is true when loading turnover report */
	isLoading: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, turnover type */
	type: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		turnover: state.report.turnover,
		isLoading: state.report.isLoading,
		session: state.auth.session,
		type: state.report.type
	};
};

export default connect(mapStateToProps, null)(Turnover);
