import  { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import runMarketUtilsFunction from "utils/markets/run";
import { GAME_TYPE } from "constants/game.constants";
import { groupByQty } from "utils/common";

const SeasonList = ({ currentGameType, marketNames, children, activeTab, isUncommon = false, showOddNames = true }) => {
	const { t } = useTranslation();

	const marketHeaderData = useMemo(() => {
		return marketNames.reduce(
			(acc, market) => {
				let transLationText = "";
				if (["Total", "AsianTotal"].includes(market.group)) {
					transLationText = t(`markets.${currentGameType}.${market.title}`).replace(/ %team(1|2)%/, "") + ` (${market.argument})`;
				} else if (["HomeTotal", "AsianHomeTotal", "AwayTotal", "AsianAwayTotal"].includes(market.group)) {
					transLationText =
						t(`markets.${currentGameType}.${market.title}`)
							.split(" ")
							.reverse()
							.join(" ")
							.replace(/%team(1|2)%/, (...args) => args[1]) +
						" " +
						market.argument;
				} else if (["Handicap", "AsianHandicap"].includes(market.group)) {
					transLationText = `${t(`markets.${currentGameType}.${market.title}`)} (${market.argument})`;
				} else if (["MatchEndsInOvertime", "MatchEndsInPenaltyShootouts"].includes(market.group)) {
					transLationText = t(`cashier.${market.title}`);
				} else {
					transLationText = t(`markets.${currentGameType}.${market.title}`);
				}

				if (!["HalfMatch", "CorrectScore6Goals", "fulltime", "round"].includes(activeTab)) {
					acc.titles.push(
						<div key={market.group + market.argument} className="vs--markets-table-cell vs--flex vs--align-center vs--justify-center vs--markets-border vs--pl-12 vs--pr-12" data-col={market.marketCol}>
							<span title={transLationText} className="vs--title vs--font-exstrasmall vs--font-regular">
								{transLationText}
							</span>
						</div>
					);
				}

				acc.oddNames = acc.oddNames.concat(
					groupByQty(market.outcomes !== "*" ? market.outcomes : [market.group], ["HalfMatch", "CorrectScore6Goals"].includes(market.group) ? 1 : 3).map((outcomeArr, i) => (
						<div key={market.group + market.argument + i} className="vs--markets-table-cell vs--market-double-sub vs--flex vs--justify-around vs--markets-border-dark" data-col={market.marketCol}>
							{outcomeArr.map((outcome, i) => {
								const nameForMarketGroup = runMarketUtilsFunction("getNameForMarketGroup", [market.group, outcome, market.argument], currentGameType);
								const text = runMarketUtilsFunction("makeName", [nameForMarketGroup, null, market.argument, currentGameType, false, false], currentGameType);
								return (
									<div key={market.group + market.argument + outcome} className="vs--markets-table-cell-factor vs--flex vs--align-center vs--justify-center">
										<span title={text} className="vs--title vs--font-smallest vs--font-medium vs--pt-4 vs--pb-4">
											{text}
										</span>
									</div>
								);
							})}
						</div>
					))
				);

				return acc;
			},
			{ titles: [], oddNames: [] }
		);
	}, [activeTab, marketNames]);

	return (
		<div className="vs--markets-list" data-tab={activeTab}>
			<div className="vs--markets-overflow">
				{marketHeaderData?.titles?.length > 0 ? (
					<div className="vs--markets-table-row-head vs--flex vs--align-center vs--justify-start">
						<div className=/*vs--markets-table-cell*/ "vs--markets-table-titles vs--flex vs--flex-equal vs--justify-around">
							<div className=/*vs--markets-table-cell*/ " vs--flex vs--flex-equal vs--justify-center">
								{!isUncommon ? <div className="vs--markets-table-cell-fixed-sub-title vs--flex vs--align-center vs--flex-equal vs--justify-center" /> : null}
								{marketHeaderData?.titles}
							</div>
						</div>
					</div>
				) : null}
				{showOddNames || !isUncommon ? (
					<div className="vs--markets-table-row-head-sub-bar vs--flex vs--align-center vs--justify-start">
						{!isUncommon ? <div className="vs--markets-table-cell-fixed-sub-odd-names vs--flex-equal vs--flex vs--align-center vs--justify-center" /> : null}
						{showOddNames ? marketHeaderData?.oddNames : null}
					</div>
				) : null}
				<div className="vs--markets-table-body">{children}</div>
			</div>
		</div>
	);
};

SeasonList.propTypes = {
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Active tab */
	activeTab: PropTypes.string,
	/** Names of markets for displyaing like column */
	marketNames: PropTypes.arrayOf(PropTypes.object).isRequired,
	/** Children(s) of SeasonList component  */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	/** Flag for display div for padding */
	isUncommon: PropTypes.bool,
	/** Flag for display odds name */
	showOddNames: PropTypes.bool
};

export default SeasonList;
