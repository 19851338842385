import { buildPathToStaticFolderOfCDN } from "utils/common";

export const JACKPOT_LEVEL = {
	BRONZE: 1,
	SILVER: 2,
	GOLD: 4
};

export const JACKPOT_LEVELS_ICONS = {
	[`level-${JACKPOT_LEVEL.BRONZE}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-1.svg"),
	[`level-${JACKPOT_LEVEL.SILVER}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-2.svg"),
	[`level-${JACKPOT_LEVEL.GOLD}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-4.svg")
}

export const JACKPOT_LEVELS_NAMES = {
	[JACKPOT_LEVEL.BRONZE]: "common.bronze",
	[JACKPOT_LEVEL.SILVER]: "common.silver",
	[JACKPOT_LEVEL.GOLD]: "common.gold"
}

export const MAX_HIT_AMOUNT_NUM_LENGTH = 10; // taken from Jackpot Max Hit Amount validation in Admin project

export const JACKPOT_ANIMATIONS_TIMEOUTS = {
	ACTIVE: 800,
	INACTIVE: 600,
	TRANSITION_SVG: 2000,
	CLOSING_SVG: 12000,
	LEVELS_BETWEEN_DELAY: 1000
};