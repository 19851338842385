import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import moment from "moment";

import { useTranslation } from "react-i18next";
import JsBarcode from "jsbarcode";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import useIntegrationType from "hooks/useIntegrationType";
import { binaryToFlags, printElement, numberWithSpaces, makeCurrencyText, isLeagueGame, isChampionsCupGame, buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common";
import { getBetStateText } from "utils/bets";

import { TICKET_TYPE, TICKET_GENERAL_DETAILS, TICKET_MARKET_DETAILS, TICKET_REDEMPTION_DATE_VALUES } from "constants/ticket.constants";
import { BETSLIP_MODES } from "constants/betslip.constants";
import { GAME_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";
import { BETSHOP_BONUS_CONFIG_TYPE, BONUS_TYPE, PROJECT_TYPE, TICKET_PAYOUT_TYPE } from "constants/common.constants";

import sessionType from "types/session.type";
import ticketType from "types/ticket.type";

import { sendPrintTicketData, sendReprintTicketData, getQRImage } from "store/actions/tickets/ticket.actions";
import { JACKPOT_LEVELS_NAMES, JACKPOT_LEVEL } from "constants/bonus.constants";
import bonusType from "types/bonus.type";

const jackpotLevelValues = Object.values(JACKPOT_LEVEL)

/** Ticket Component */
const Ticket = ({ session, ticket, sendPrintTicketData, sendReprintTicketData, getQRImage }) => {
	const { t } = useTranslation(["ticket"]);
	const integrationType = useIntegrationType();
	const [barcodeLoaded, setBarcodeLoaded] = useState(false);
	const [qrLoaded, setQRLoaded] = useState(false);
	const [qrCode, setQRCode] = useState(null);
	const [showQRImg, setShowQRImg] = useState(false);

	const isJackpotPayoutOnly = (TICKET_TYPE.PAYOUT === ticket?.ticketType && ticket?.payoutType === TICKET_PAYOUT_TYPE.JACKPOT)
	const isPayoutBetOnly = (TICKET_TYPE.PAYOUT === ticket?.ticketType && ticket?.payoutType === TICKET_PAYOUT_TYPE.BETSLIP)
	const isPayoutAll = (TICKET_TYPE.PAYOUT === ticket?.ticketType && ticket?.payoutType === TICKET_PAYOUT_TYPE.ALL)
	const allowedJackpotLevelType = ticket.jackpotLevelType ? binaryToFlags(jackpotLevelValues, ticket.jackpotLevelType) : jackpotLevelValues
	const jackpotsPayoutAmount = Number(
		ticket?.wonJackpots?.reduce(
			(acc, wonJackpot) => {
				if (allowedJackpotLevelType.includes(wonJackpot.levelType)) {
					return acc + wonJackpot.amount
				}
				return acc
			},
			0
		) ?? 0
	)

	const shouldBePrintedTwice = () => {
		let should = false;
		const betShopBonusConfigs = session?.betShopBonusConfigs ?? {};

		if (betShopBonusConfigs.enabled && ticket.ticketType === TICKET_TYPE.BET) {
			if (ticket.type === BETSLIP_MODES.SINGLE) {
				if (betShopBonusConfigs.type === BETSHOP_BONUS_CONFIG_TYPE.EQUAL) {
					should = ticket.bets.every((b) => Number(b.stake) === (betShopBonusConfigs.amount ?? 0));
				} else {
					should = ticket.bets.every((b) => Number(b.stake) >= (betShopBonusConfigs.amount ?? 0));
				}
			} else if (ticket.type === BETSLIP_MODES.MULTI) {
				if (betShopBonusConfigs.type === BETSHOP_BONUS_CONFIG_TYPE.EQUAL) {
					should = (betShopBonusConfigs.amount ?? 0) === Number(ticket.totalAmount);
				} else {
					should = (betShopBonusConfigs.amount ?? 0) <= Number(ticket.totalAmount);
				}
			}
		}

		return should;
	};

	/** Get current ticket details
	 * @function
	 * @returns {object}
	 * @memberOf Ticket
	 */
	const getTicketDetails = () => (ticket.ticketType === TICKET_TYPE.BET ? session.betTicket : ticket.ticketType === TICKET_TYPE.CANCEL ? session.cancelTicket : ticket.ticketType === TICKET_TYPE.PAYOUT ? session.payoutTicket : null);

	/** Get current ticket general details
	 * @function
	 * @returns {Array}
	 * @memberOf Ticket
	 */
	const getGeneralDetails = () => binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), getTicketDetails().generalDetails);

	/** Get current ticket markets selection
	 * @function
	 * @returns {Array}
	 * @memberOf Ticket
	 */
	const getMarketSelection = () => binaryToFlags(Object.values(TICKET_MARKET_DETAILS), getTicketDetails().marketSelection);

	/** Get redemption days count
	 * @function
	 * @returns {number}
	 * @memberOf Ticket
	 */
	const getRedemptionDays = () => {
		switch (session.ticketRedemptionPeriod) {
			case TICKET_REDEMPTION_DATE_VALUES.SEVEN_DAYS:
				return 7;
			case TICKET_REDEMPTION_DATE_VALUES.FORTEEN_DAYS:
				return 14;
			case TICKET_REDEMPTION_DATE_VALUES.THIRTHY_DAYS:
				return 30;
			case TICKET_REDEMPTION_DATE_VALUES.FORTYFIVE_DAYS:
				return 45;
			case TICKET_REDEMPTION_DATE_VALUES.SIXTY_DAYS:
				return 60;
			case TICKET_REDEMPTION_DATE_VALUES.NINTY_DAYS:
				return 90;
			case TICKET_REDEMPTION_DATE_VALUES.HUNDREDTWENTY_DAYS:
				return 120;
			default:
				return 0;
		}
	};

	const isBonus = ticket?.bonusId && ticket?.bonusType === BONUS_TYPE.FREEBET;

	/** Is the ticket  winner of double dooble
	 * @function
	 * @returns {number}
	 * @memberOf Ticket
	 */
	const isDoubleDoobleWinner = () => session.isDoubleDooble && ticket.additionalFactor && ticket.bonusType === BONUS_TYPE.DOUBLEDOOBLE;

	const isJackpotWinner = () => Array.isArray(ticket.wonJackpots) && ticket.wonJackpots.length > 0

	/* Effect for handling the QR image visibility state */
	useEffect(() => {
		setShowQRImg(getTicketDetails().enableQRPrinting, qrCode);
	}, [getTicketDetails().enableQRPrinting, qrCode]);

	/** Print Ticket */
	useEffect(() => {
		const ticketDetails = getTicketDetails();
		const isTicketType_bet = ticket.ticketType === TICKET_TYPE.BET;
		const isTicketType_cancel = ticket.ticketType === TICKET_TYPE.CANCEL && session.cancelTicket.printCancellationTickets;
		const isTicketType_payout = ticket.ticketType === TICKET_TYPE.PAYOUT && session.payoutTicket.printPayoutTickets;
		const barcodeCondition = barcodeLoaded || !ticketDetails.enableBarcodePrinting;
		const qrCodeCondition = qrLoaded || !ticketDetails.enableQRPrinting || !isTicketType_bet;

		if ((isTicketType_bet || isTicketType_cancel || isTicketType_payout) && barcodeCondition && qrCodeCondition) {
			printElement(
				"vs--print-wrapper",
				() => {
					if (ticket.isReprint) {
						sendReprintTicketData(ticket.id, ticket.ticketType);
					} else {
						sendPrintTicketData(ticket.id, ticket.ticketType, ticket.payoutType);
					}
				},
				shouldBePrintedTwice()
			);
		}
	}, [barcodeLoaded, qrLoaded]);

	useEffect(() => {
		const canvas = document.getElementById("barcode-canvas");
		if (canvas) {
			let id = ticket.id.toString();
			if (id.length < 5) {
				id = "0" + id;
			}

			JsBarcode(canvas, "vs" + id, {
				format: "CODE39",
				fontSize: 16,
				textMargin: 2,
				font: "Roboto",
				lineColor: "#000000",
				margin: 0,
				height: 50,
				displayValue: false
			});

			canvas.onload = () => {
				setBarcodeLoaded(true);
			};
		}
	}, []);

	/** Load QR code */
	useEffect(() => {
		if (showQRImg && ticket.ticketType === TICKET_TYPE.BET) {
			getQRImage(ticket.id, (data) => {
				setQRCode(data);
				const img = document.getElementById("qr-image");
				img.setAttribute("src", data);
				img.onload = () => {
					setQRLoaded(true);
				};
			});
		}
	}, [showQRImg]);

	return (
		<Fragment>
			<div id="vs--print-wrapper" className="vs--print-wrapper">
				<div className="vs--print">
					{
						!isJackpotWinner() && (session.isDoubleDooble && ticket.ticketType === TICKET_TYPE.BET)
							? (
								<div className="vs--print-ticket-bonus" data-win={isDoubleDoobleWinner()}>
									<div className="vs--print-ticket-bonus-coin">
										<img
											src={(
												isDoubleDoobleWinner()
													? buildPathToStaticFolderOfCDN("cashier-images/double-coin-x.svg")
													: buildPathToStaticFolderOfCDN("cashier-images/double-coin.svg")
											)}
										/>
										<h3 className={`vs--print-ticket-bonus-coin-text${isDoubleDoobleWinner() ? " vs--print-ticket-bonus-coin-text-winner" : ""}`}>{isDoubleDoobleWinner() ? t("cashier.congratulations") : t("cashier.becomeOneIn").replace("%%X%%", session.doubleDoobleQuantity)}</h3>
										{isDoubleDoobleWinner() ? <h3 className="vs--print-ticket-bonus-coin-text vs--print-ticket-bonus-coin-text-num">{t("2X")}</h3> : null}
									</div>
									<h4 className="vs--print-ticket-bonus-info vs--pl-16 vs--pr-16 vs--mt-16 vs--text-center">
										{isDoubleDoobleWinner()
											? t("cashier.doubleDoubleTicketWinText")
												.replace("%%X%%", makeCurrencyText(ticket.possibleWin, session.currency))
												.replace("%%Y%%", makeCurrencyText(Number(ticket.possibleWin) * ticket.additionalFactor, session.currency))
											: t("cashier.doubleDoobleTicketTryAgainText")}
									</h4>
								</div>
							)
							: null
					}
					{session.betTicket.ticketLogoId && (
						<div className="vs--print-header">
							<img src={`${import.meta.env.SYSTEM_CDN_URL}/${session.partnerId}/images/${session.betTicket.ticketLogoId}_ticket_logo.png`} alt="logo" />
						</div>
					)}
					<div className="vs--print-header-sub">
						{isBonus && (
							<div className="vs--print-header-sub-title">
								<h2 className="vs--font-small">
									{t("bonus.freeBet")}&nbsp;{t("bonus.bonus")}
								</h2>
							</div>
						)}
						{
							isJackpotWinner() && !isPayoutBetOnly
								? (
									<Fragment>
										<div
											className="vs--print-header-sub-title vs--print-header-sub-title-jackpot"
										>
											<img
												className="vs--jackpot-coins-img"
												src={buildPathToStaticFolderOfCDN("cashier-images/jackpot-coins.svg")}
											/>
											<div className="vs--flex vs--flex-col">
												{
													(TICKET_TYPE.BET === ticket?.ticketType) && isDoubleDoobleWinner()
														? (
															<Fragment>
																<h3 className="vs--fs-32">{t("cashier.megaWin").toUpperCase()}</h3>
																<h3 className="vs--font-small vs--mt-40">{t("cashier.jackpotWinner").toUpperCase()}</h3>
																<h3 className="vs--font-small vs--mt-8">+</h3>
																<h3 className="vs--font-small vs--mt-8">{t("cashier.doubleDooble").toUpperCase()}</h3>
																<div>{t("cashier.wonJackpotAndDoubleDoobleParticipant")}</div>
															</Fragment>
														)
														: null
												}
												{
													isDoubleDoobleWinner() && (TICKET_TYPE.PAYOUT === ticket?.ticketType) && !isJackpotPayoutOnly
														? (
															<Fragment>
																<h3 className="vs--fs-32">{t("cashier.jackpot").toUpperCase()}</h3>
																<h3 className="vs--fs-32 vs--mt-4">&</h3>
																<h3 className="vs--fs-32 vs--mt-4">{t("cashier.doubleDooble").toUpperCase()}</h3>
																<h3 className="vs--fs-32 vs--mt-4">{t("cashier.Winner").toUpperCase()}</h3>
															</Fragment>
														)
														: isJackpotPayoutOnly && (TICKET_TYPE.PAYOUT === ticket?.ticketType) || (!isDoubleDoobleWinner() && TICKET_TYPE.BET === ticket?.ticketType)
															? (
																<Fragment>
																	<h3 className="vs--font-small vs--mt-40">{t("cashier.congratulationsYouWon").toUpperCase()}</h3>
																	<h3 className="vs--fs-32">{t("cashier.jackpot").toUpperCase()}</h3>
																</Fragment>
															)
															: null
												}
												<div className="vs--flex vs--mt-10 vs--justify-center vs--align-center">
													<i className="ic_virtualSports" />
													<h1>{t("common.virtualSports")}</h1>
												</div>
											</div>
										</div>

									</Fragment>
								)
								: (
									<Fragment>
										{ticket.ticketType === TICKET_TYPE.PAYOUT && isDoubleDoobleWinner() && (
											<div className="vs--print-header-sub-title">
												<h2 className="vs--font-small">{t("cashier.doubleDooble")}</h2>
											</div>
										)}
										<div className="vs--print-header-sub-title">
											<i className="ic_virtualSports" />
											<h1>{t("common.virtualSports")}</h1>
										</div>
									</Fragment>
								)
						}
					</div>
					{ticket.ticketType === TICKET_TYPE.CANCEL && <h6>{t("cashier.cancellation")}</h6>}
					{ticket.ticketType === TICKET_TYPE.PAYOUT && <h6>{t("cashier.payout")}</h6>}
					{ticket.isReprint ? <h6>{`${t("cashier.reprinted")} ${shouldBePrintedTwice() ? "(%%%TICKET_NUMBER%%%)" : ""}`}</h6> : shouldBePrintedTwice() ? <h6>{"%%%TICKET_NUMBER%%%"}</h6> : null}
					{(getGeneralDetails().includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) ||
						getGeneralDetails().includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) ||
						getGeneralDetails().includes(TICKET_GENERAL_DETAILS.TICKET_ID) ||
						getGeneralDetails().includes(TICKET_GENERAL_DETAILS.PRINTED_DATETIME) ||
						getGeneralDetails().includes(TICKET_GENERAL_DETAILS.CASHIER_ID) ||
						(getGeneralDetails().includes(TICKET_GENERAL_DETAILS.VALIDATION_DATE) && session.ticketRedemptionPeriod !== TICKET_REDEMPTION_DATE_VALUES.NEVER)) && (
							<div className="vs--print-info vs--print-info-dashed">
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.BETSHOP_NAME) && (
									<div className="vs--print-info-row">
										<span>{t("cashier.betshop")}</span>
										<span>{session.projectName}</span>
									</div>
								)}
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.BETSHOP_ADDRESS) && (
									<div className="vs--print-info-row">
										<span>{t("cashier.address")}</span>
										<span>{session.address}</span>
									</div>
								)}
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.TICKET_ID) && (
									<div className="vs--print-info-row">
										<span>{t("cashier.ticketID")}</span>
										<b>{`${integrationType === PROJECT_TYPE.IFRAME ? "vs" : ""}${ticket.id}`}</b>
									</div>
								)}
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.PRINTED_DATETIME) && (
									<div className="vs--print-info-row">
										<span>{t("cashier.printed")}</span>
										<span>{moment.utc().local().format(DATE_TIME_FORMAT)}</span>
									</div>
								)}
								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.CASHIER_ID) && (
									<div className="vs--print-info-row">
										<span>{t("cashier.cashier")}</span>
										<span>{session?.cashier?.userName}</span>
									</div>
								)}

								{getGeneralDetails().includes(TICKET_GENERAL_DETAILS.VALIDATION_DATE) && session.ticketRedemptionPeriod !== TICKET_REDEMPTION_DATE_VALUES.NEVER && (
									<div className="vs--print-info-row">
										<span>{t("cashier.validTill")}</span>
										<span>{moment().add(getRedemptionDays(), "days").set({ hour: moment().hour(), minute: moment().minutes() }).format(DATE_TIME_FORMAT)}</span>
									</div>
								)}
							</div>
						)}
					{[TICKET_TYPE.BET, TICKET_TYPE.PAYOUT].includes(ticket.ticketType) && isJackpotWinner() ? (
						<Fragment>
							<div className="vs--print-info vs--print-info-dashed-both">
								<div className="vs--print-info-row">
									<b>{t("cashier.jackpotInformation")}</b>
								</div>
								{
									ticket.wonJackpots.reduce((acc, wonJackpot, i, arr) => {
										if (allowedJackpotLevelType.includes(wonJackpot.levelType)) {
											acc.push(
												<Fragment key={wonJackpot.id}>
													<div className="vs--print-info-row">
														<span>{t("cashier.jackpotId")}</span>
														<span>{wonJackpot.id}</span>
													</div>
													<div className="vs--print-info-row">
														<span>{t("cashier.jackpotLevel")}</span>
														<span>{t(JACKPOT_LEVELS_NAMES[wonJackpot.levelType])}</span>
													</div>
													<div className="vs--print-info-row">
														<span>{t("cashier.jackpotName")}</span>
														<span>{wonJackpot.poolName ?? " - "}</span>
													</div>
													<div className="vs--print-info-row">
														<span>{t("cashier.jackpotWinDate")}</span>
														<span>{moment.utc(wonJackpot.winTime).local().format(DATE_TIME_FORMAT)}</span>
													</div>
													<div className={mergeClassNames("vs--print-info-row", (i !== (arr.length - 1)) && "vs--mb-10")}>
														<b>{t("cashier.jackpotTicketWinAmount")}</b>
														<b>{makeCurrencyText(Number(wonJackpot.amount), session.currency)}</b>
													</div>
												</Fragment>
											)
										}
										return acc;
									}, [])
								}
							</div>
							{
								isJackpotPayoutOnly
									? null
									: (
										<div className="vs--print-info">
											<div className="vs--print-info-row">
												<b>{t("cashier.ticketInformation")}</b>
											</div>
										</div>
									)
							}
						</Fragment>
					) : null}

					{!isJackpotPayoutOnly && (getMarketSelection().includes(TICKET_MARKET_DETAILS.TEAM_NAMES) ||
						getMarketSelection().includes(TICKET_MARKET_DETAILS.GAME_TYPE) ||
						getMarketSelection().includes(TICKET_MARKET_DETAILS.DRAW_ID) ||
						getMarketSelection().includes(TICKET_MARKET_DETAILS.START_TIME) ||
						getMarketSelection().includes(TICKET_MARKET_DETAILS.MARKETS) ||
						(getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) && ticket.type === BETSLIP_MODES.SINGLE) ||
						(getMarketSelection().includes(TICKET_MARKET_DETAILS.POSSIBLE_WIN) && ticket.type === BETSLIP_MODES.SINGLE)) && (
							<div className="vs--print-info">
								{ticket.bets.map((bet) => {
									const team1 = bet?.gameData?.team1;
									const team2 = bet?.gameData?.team2;
									const gameType = bet?.gameType ?? 0;

									return (
										<div className="vs--print-info-bet" key={bet.id}>
											{getMarketSelection().includes(TICKET_MARKET_DETAILS.TEAM_NAMES) && (
												<div className="vs--print-info-row">
													<b>
														{team1 && team2
															? isLeagueGame(gameType) || isChampionsCupGame(gameType)
																? `${team1.countryCode ?? ""} - ${team2.countryCode ?? ""}`
																: `${t(`countries.${team1.countryCode ?? ""}`)} - ${t(`countries.${team2.countryCode ?? ""}`)}`
															: bet?.gameData?.venue
																? bet.gameData.venue
																: ""}
													</b>
												</div>
											)}
											{getMarketSelection().includes(TICKET_MARKET_DETAILS.GAME_TYPE) && (
												<div className="vs--print-info-row">
													<span>{t("cashier.gameType")}</span>
													<span>{t(`common.${GAME_TYPE_TEXT_KEYS[gameType || ""]}`)}</span>
												</div>
											)}
											{getMarketSelection().includes(TICKET_MARKET_DETAILS.DRAW_ID) && (
												<div className="vs--print-info-row">
													<span>{t("common.eventId")}</span>
													<span>{bet.eventId}</span>
												</div>
											)}
											{getMarketSelection().includes(TICKET_MARKET_DETAILS.START_TIME) && (
												<div className="vs--print-info-row">
													<span>{t("cashier.start")}</span>
													<span>{moment.utc(bet.startTime).local().format(DATE_TIME_FORMAT)}</span>
												</div>
											)}

											{getMarketSelection().includes(TICKET_MARKET_DETAILS.MARKETS) && (
												<div className={"vs--print-info-row" + (gameType === GAME_TYPE.KENO ? " vs--print-info-row-large" : "")}>
													<span>{bet.groupTitle}</span>
													<span>{bet.showName}</span>
												</div>
											)}
											{getMarketSelection().includes(TICKET_MARKET_DETAILS.MARKETS) && (
												<div className="vs--print-info-row">
													<span>{t("bet.odds")}</span>
													<span>{numberWithSpaces(bet.factor)}</span>
												</div>
											)}
											{getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) && ticket.type === BETSLIP_MODES.SINGLE && (
												<div className="vs--print-info-row">
													<span>
														{isBonus ? <>{t("bonus.bonus")}&nbsp;</> : null}
														{t("bet.stake")}
													</span>
													<span>{makeCurrencyText(bet.stake, session.currency)}</span>
												</div>
											)}
											{ticket.type === BETSLIP_MODES.SINGLE && getMarketSelection().includes(TICKET_MARKET_DETAILS.POSSIBLE_WIN) && (
												<div className="vs--print-info-row">
													<span>
														{isBonus ? <>{t("bonus.bonus")}&nbsp;</> : null}
														{t("bet.possibleWin")}
													</span>
													<span>{makeCurrencyText(Number(bet.stake) * Number(bet.factor), session.currency) + (isDoubleDoobleWinner() ? " X " + ticket.additionalFactor : "")}</span>
												</div>
											)}

											{ticket.type === BETSLIP_MODES.SINGLE && getMarketSelection().includes(TICKET_MARKET_DETAILS.POSSIBLE_WIN) && isDoubleDoobleWinner() && (
												<div className="vs--print-info-row">
													<span>{t("cashier.doubleDoobleWin")}</span>
													<span>{makeCurrencyText(Number(bet.stake) * Number(bet.factor) * 2, session.currency)}</span>
												</div>
											)}

											{getMarketSelection().includes(TICKET_MARKET_DETAILS.STATE) && (
												<div className="vs--print-info-row">
													<span>{t("bet.status")}</span>
													<span>{getBetStateText(bet.state)}</span>
												</div>
											)}
										</div>
									);
								})}
							</div>
						)}

					{!isJackpotPayoutOnly && (getMarketSelection().includes(TICKET_MARKET_DETAILS.BET_TYPE) || getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) || getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN)) && (
						<div className="vs--print-info vs--print-info-dashed">
							{getMarketSelection().includes(TICKET_MARKET_DETAILS.BET_TYPE) && (
								<div className="vs--print-info-row">
									<span>
										{isBonus ? <>{t("bonus.bonus")}&nbsp;</> : null}
										{t("bet.betType")}
									</span>
									<b>{ticket.type === BETSLIP_MODES.SINGLE ? t("bet.single") : t("bet.multi")}</b>
								</div>
							)}
							{getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) && (
								<div className="vs--print-info-row">
									<span>
										{isBonus ? <>{t("bonus.bonus")}&nbsp;</> : null}
										{ticket.ticketType === TICKET_TYPE.BET ? t("bet.stake") : ticket.ticketType === TICKET_TYPE.CANCEL ? t("cashier.cancellation") : ticket.ticketType === TICKET_TYPE.PAYOUT ? t("cashier.payout") : null}
									</span>
									<b>
										{makeCurrencyText(ticket.ticketType !== TICKET_TYPE.PAYOUT ? ticket.totalAmount : (ticket.bonusId ? ticket.redeem : ticket.winning) / (isDoubleDoobleWinner() ? ticket.additionalFactor : 1), session.currency) +
											(ticket.ticketType === TICKET_TYPE.PAYOUT && isDoubleDoobleWinner() ? " X " + ticket.additionalFactor : "")}
									</b>
								</div>
							)}

							{getMarketSelection().includes(TICKET_MARKET_DETAILS.STAKE) && ticket.ticketType === TICKET_TYPE.PAYOUT && isDoubleDoobleWinner() && (
								<div className="vs--print-info-row">
									<span>{t("cashier.doubleDooblePayout")}</span>
									<b>{makeCurrencyText(ticket.winning, session.currency)}</b>
								</div>
							)}

							{getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN) && (
								<div className="vs--print-info-row">
									<span>
										{isBonus ? <>{t("bonus.bonus")}&nbsp;</> : null}
										{t("cashier.maxPossibleWin")}
									</span>
									<b>{makeCurrencyText(ticket.possibleWin, session.currency) + (isDoubleDoobleWinner() ? " X " + ticket.additionalFactor : "")}</b>
								</div>
							)}

							{getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_POSSIBLE_WIN) && isDoubleDoobleWinner() && (
								<div className="vs--print-info-row">
									<span>{t("cashier.maxDoubleDoobleWin")}</span>
									<b>{makeCurrencyText(Number(ticket.possibleWin) * 2, session.currency)}</b>
								</div>
							)}
						</div>
					)}

					{!isJackpotPayoutOnly && getMarketSelection().includes(TICKET_MARKET_DETAILS.MAX_WIN_PER_TICKET) && (
						<div
							className={
								mergeClassNames(
									"vs--print-info",
									isJackpotWinner() && isPayoutAll && "vs--print-info-dashed"
								)
							}
						>
							<div className="vs--print-info-row">
								<span>{t("cashier.maxWinPerTicket")}</span>
								<b>{makeCurrencyText(ticket.type === BETSLIP_MODES.SINGLE ? session?.currency?.singlePossibleWinMax ?? Infinity : session?.currency?.multiPossibleWinMax ?? Infinity, session.currency)}</b>
							</div>
						</div>
					)}

					{
						(isJackpotPayoutOnly || (isJackpotWinner() && isPayoutAll)) && Array.isArray(ticket.wonJackpots) && (

							<div className="vs--print-info">
								<div className="vs--print-info-row">
									<b>{t(isJackpotPayoutOnly ? "cashier.jackpotPayout" : "cashier.jackpotTotalPayout")}</b>
									<b>
										{
											makeCurrencyText(jackpotsPayoutAmount, session.currency)
										}
									</b>
								</div>
							</div>
						)
					}

					{
						((isJackpotWinner() && isPayoutAll)) && Array.isArray(ticket.wonJackpots) && (
							<div className="vs--print-info">
								<div className="vs--print-info-row">
									<b>{t("cashier.ticketTotalPayout")}</b>
									<b>{makeCurrencyText(Number(ticket.winning), session.currency)}</b>
								</div>
							</div>
						)
					}
					{
						((isJackpotWinner() && isPayoutAll)) && Array.isArray(ticket.wonJackpots) && (
							<div className="vs--print-info">
								<div className="vs--print-info-row">
									<b>{t("cashier.totalPayout")}</b>
									<b>{makeCurrencyText(Number(jackpotsPayoutAmount) + Number(ticket.winning), session.currency)}</b>
								</div>
							</div>
						)
					}

					<div className="vs--print-footer">
						<div className="vs--print-footer-inner">
							<div className="vs--print-footer-barcode">
								{getTicketDetails().enableBarcodePrinting && (
									<Fragment>
										<img id="barcode-canvas" />
									</Fragment>
								)}
							</div>
							<div className="vs--print-footer-qr">{showQRImg ? <img id="qr-image" /> : null}</div>
						</div>
						{getTicketDetails().customText && <span className="vs--print-footer-custom-text">{getTicketDetails().customText}</span>}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

/** Ticket propTypes
 * PropTypes
 */
Ticket.propTypes = {
	/** Redux state property, the printing ticket data */
	ticket: ticketType,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux action to save print state for ticket */
	sendPrintTicketData: PropTypes.func,
	/** Redux action to save reprint state for ticket */
	sendReprintTicketData: PropTypes.func,
	/** Redux action to get qr image */
	getQRImage: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		session: { ...state.auth.session /* , isDoubleDooble: true */ },
		ticket: { ...state.tickets.printingTicket /* , additionalFactor: 1, bonusType: BONUS_TYPE.DOUBLEDOOBLE, bonusId: "asdfasdf" */ }
	};
};

const mapDispatchToProps = (dispatch) => ({
	sendPrintTicketData: (id, type, payoutType) => {
		dispatch(sendPrintTicketData(id, type, payoutType));
	},
	sendReprintTicketData: (id, type) => {
		dispatch(sendReprintTicketData(id, type));
	},
	getQRImage: (id, cb) => {
		dispatch(getQRImage(id, cb));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
