import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

import { PERIOD, PENALTY_SHOOTOUT_MARKETS_TABS, PENALTY_SHOOTOUT_MARKETS_GROUPS, PENALTY_MISS_CASE_MARKETS, PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS } from "constants/market.constants";

class PenaltyShotoutsMarkets extends BaseMarkets {
	constructor() {
		super();
		this.overUnderArgumentPriority = [2.5, 1.5, 3.5, 2, 1, 3, 0.5, 4, 4.5, 5, 5.5];
	}

	matchGoalsCountOutcomes() {
		return Array.from({ length: 10 }, (_, i) => `Goal${i + 1}`);
	}

	correctScorePerShotOutcomes() {
		return [
			"Home0Away0", "Home0Away1",
			"Home1Away0", "Home1Away1"
		];
	}

	correctScoreOutcomes() {
		return [
			"Home1Away0", "Home0Away1",
			"Home2Away0", "Home0Away2",
			"Home2Away1", "Home1Away2",
			"Home3Away0", "Home0Away3",
			"Home3Away1", "Home1Away3",
			"Home3Away2", "Home2Away3",
			"Home4Away1", "Home1Away4",
			"Home4Away2", "Home2Away4",
			"Home4Away3", "Home3Away4",
			"Home5Away3", "Home3Away5",
			"Home5Away4", "Home4Away5",
			"Home6Away5", "Home5Away6"
		];
	}

	shotOutcomes(isMissCase) {
		if (isMissCase) {
			return ["OutOfGoals", "GoalSaves", "GoalWoodworks"];
		}
		return Array.from({ length: 9 }, (_, i) => `Sector${i + 1}`);
	}

	/** Get All possible tabs
	 * @function
	 * @returns {object}
	 * @memberof PenaltyShotoutsMarkets
	 */
	getGroups() {
		return Object.keys(this.getGroupsStructure());
	}

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof PenaltyShotoutsMarkets
	 */
	getGroupsStructure() {
		return {
			[PENALTY_SHOOTOUT_MARKETS_TABS.MAIN]: [
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, outcomes: ["Home", "Away"],  marketsCount: 2, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: null },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_OUTCOME, outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: null },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT, outcomes: ["HomeWinAfterOvertime", "AwayWinAfterOvertime"],  marketsCount: 2, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: null },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, outcomes: ["Over", "Under"], marketsCount: 8, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => [7, 7.5, 8, 8.5].includes(Number(arg)) },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT, outcomes: this.matchGoalsCountOutcomes(), marketsCount: 12, marketCol: 2, gridBy: 'col', oddBy: 'row' },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, outcomes: ["Over", "Under"], marketsCount: 8, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => [8.5, 9, 9.5, 10.5].includes(Number(arg)) },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE, outcomes: ["Yes", "No"], marketsCount: 12, marketCol: 2, gridBy: 'col', oddBy: 'row' },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, outcomes: ["Home", "Away"], marketsCount: 2, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => [-1.5, 1.5].includes(Number(arg)) },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP, outcomes: ["Home", "Away"], marketsCount: 2, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => [-1.25, 1.25].includes(Number(arg)) },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, outcomes: ["Over", "Under"], marketsCount: 8, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => [1.5, 2.5, 3.5, 4.5].includes(Number(arg)) },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, outcomes: ["Over", "Under"], marketsCount: 8, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => [6.25, 7.75, 8.25, 8.75].includes(Number(arg)) },
			],
			[PENALTY_SHOOTOUT_MARKETS_TABS.CORRECT_SCORE]: [
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, outcomes: this.correctScoreOutcomes(), marketsCount: 24, marketCol: 7, gridBy: 'row', oddBy: 'col', argument: null },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT, outcomes: this.correctScorePerShotOutcomes(), marketsCount: 12, marketCol: 2, gridBy: 'col', oddBy: 'row' }
			],
			[PENALTY_SHOOTOUT_MARKETS_TABS.PENALTY_SHOT_OUTCOME]: [
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS, outcomes: ["Goal", "Miss"], marketsCount: 6, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => Number(arg) < 7 },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS, outcomes: ["Goal", "Miss"], marketsCount: 6, marketCol: 2, gridBy: 'col', oddBy: 'row', argument: arg => Number(arg) < 7 },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, outcomes: this.shotOutcomes(false), marketsCount: 6, marketCol: 3, gridBy: 'col', oddBy: 'row', argument: arg => Number(arg) < 7 },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME, outcomes: this.shotOutcomes(false), marketsCount: 6, marketCol: 3, gridBy: 'col', oddBy: 'row', argument: arg => Number(arg) < 7 },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME], outcomes: this.shotOutcomes(true), marketsCount: 6, marketCol: 3, gridBy: 'col', oddBy: 'row', argument: arg => Number(arg) < 7 },
				{ group: PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME, period: PERIOD.PENALTY_SHOOTOUT, title: PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME], outcomes: this.shotOutcomes(true), marketsCount: 6, marketCol: 3, gridBy: 'col', oddBy: 'row', argument: arg => Number(arg) < 7 },
			]
		}
	}

	/** Get All possible markets for tab
	 * @function
	 * @param {string } market - tab name
	 * @param {array} markets - markets to filter
	 * @returns {array}
	 * @memberof BaseMarkets
	 */
	filterMarkets(tab, markets, roundOrderNumber = null) {
		let filtered = [];
		if (!Array.isArray(markets)) {
			return filtered;
		}
		const allMarkets = this.defineArgumentForOverUnderGroup(this.getGroupsStructure({ roundOrderNumber })[tab], markets);
		allMarkets.forEach((m) => {
			const matching = markets.filter((mrkt) => mrkt.period === PERIOD.PENALTY_SHOOTOUT && ((typeof m.group === "function" && m.group(mrkt.group)) || mrkt.group === m.group) && (!m.argument || m.argument === mrkt.argument || (typeof m.argument === "function" && m.argument(mrkt.argument))));
			filtered.push({
				...m,
				matching: matching
			});
		});

		filtered = filtered.map((gr) => {
			const marketsCount = gr.marketsCount || 1;
			if (gr.matching.length > marketsCount) {
				let newMatching = gr.matching;
				if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN].includes(gr.group)) {
					newMatching = [...gr.matching].sort((a, b) => (this.overUnderArgumentPriority.indexOf(a.argument) > this.overUnderArgumentPriority.indexOf(b.argument) ? 1 : -1)).slice(0, marketsCount);
				} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP].includes(gr.group)) {
					newMatching = [...gr.matching].sort((a, b) => (a.argument > b.argument ? 1 : -1)).slice(Math.floor((gr.matching.length - marketsCount) / 2), (gr.matching.length - marketsCount) / 2 + marketsCount);
				}
				return {
					...gr,
					matching: newMatching
				};
			}
			return { ...gr };
		});
		return filtered;
	}

	/** Get market name from systemName
	 * @function
	 * @param {array } marketName - the market name
	 * @param {object} gameData - Event data
	 * @param {number} argument - the market argument
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	getNameForMarketGroup(marketName, outcome, argument) {
		if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS, PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN].includes(marketName)) {
			return outcome + " (" + argument + ")";
		} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(marketName)) {
			return "%[" + outcome + "]% (" + (outcome === "Home" ? argument : 0 - argument) + ")";
		} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.RESULT, PENALTY_SHOOTOUT_MARKETS_GROUPS.SUDDEN_DEATH_RESULT].includes(marketName)) {
			return "%" + outcome + "%";
		} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT].includes(marketName)) {
			return outcome.replace("Home", "").replace("Away", ":");
		} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.MATCH_GOALS_COUNT].includes(marketName)) {
			return outcome.replace("Goal", "");
		} else if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME].includes(marketName)) {
			if (outcome.includes("Sector")) {
				return outcome.replace("Sector", "");
			}
			return outcome;
		} else {
			return outcome;
		}
	}

	/** Transform markets array to the array , useful for markets UI
	 * @function
	 * @param {array } data - data to transform
	 * @param {object} gameData - Event data
	 * @param {Array} rtps - Array of rtps to apply on markets
	 * @param {number} gameType - The gameType
	 * @returns {object}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		data.forEach((d) => {
			const key = typeof d.argument === "number" ? d.title + "_" + d.argument : d.title;
			obj[key] = {
				group: d.title,
				odds: Array(d.marketsCount > d.matching.length ? d.matching.length : d.marketsCount).fill(d.outcomes !== "*" ? d.outcomes : (d.matching[0] ? d.matching[0].oddList : []).map((odd) => odd.outcome)),
				matching: d.matching,
				marketCol: d.marketCol,
				marketsCount: d.marketsCount,
				type: d.type,
				argument: d.argument,
				gridBy: d.gridBy,
				oddBy: d.oddBy
			};
		});

		Object.keys(obj).forEach((o) => {
			obj[o].oddsCopy = obj[o].odds.map((m) => m.slice());
			for (let index = 0; index < obj[o].oddsCopy.length; index++) {
				const matchingOdd = obj[o].matching[index];
				if (matchingOdd) {
					for (let a = 0; a < matchingOdd.oddList.length; a++) {
						const odd = matchingOdd.oddList[a];
						if (odd) {
							const i = obj[o].oddsCopy[index].indexOf(odd.outcome);
							obj[o].odds[index] = [...obj[o].odds[index]];
							obj[o].odds[index][i] = {
								name: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument),
								factor: this.applyRtp(odd.factor, [PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(o) ? o : obj[o].group, matchingOdd.period, matchingOdd.argument, odd.outcome, rtps),
								oddState: odd.state,
								outcome: odd.outcome,
								argument: matchingOdd.argument,
								id: odd.id,
								showName: this.makeName(this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument), gameData, matchingOdd.argument, gameType),
								ticketShowName: this.makeName(this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument), gameData, matchingOdd.argument, gameType, true),
								rectData: this.getRectData(this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument), gameData, gameType)
							};
						}
					}
				}
			}
			delete obj[o].matching;
			delete obj[o].oddsCopy;
		});

		const arr = [];
		Object.keys(obj).forEach((o) => {
			const group = o;
			const argument = PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[o.split("_")[0]] ? undefined : o.split("_")[1];
			const item = {
				group: group,
				argument: argument,
				marketCol: obj[o].marketCol,
				marketsCount: obj[o].marketsCount,
				odds: obj[o].odds.map((oddGroup) =>
					oddGroup.map((odd) => {
						const condition =
							typeof odd === "string" &&
							![
								PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS,
								PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_PENALTIES,
								PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_MISS,
								PENALTY_SHOOTOUT_MARKETS_GROUPS.TOTAL_GOALS_ASIAN,
								PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP,
								PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP,
								PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT
							].includes(group);
						if (condition) {
							const name = this.getNameForMarketGroup(group, odd);
							const showName = this.makeName(this.getNameForMarketGroup(group, odd), gameData, argument, gameType);
							const ticketShowName = this.makeName(this.getNameForMarketGroup(group, odd), gameData, argument, gameType, true);
							return {
								name: this.getNameForMarketGroup(group, odd),
								showName: this.makeName(this.getNameForMarketGroup(group, odd), gameData, argument, gameType),
								ticketShowName: this.makeName(this.getNameForMarketGroup(group, odd), gameData, argument, gameType, true)
							};
						}
						return odd;
					})
				),
				type: obj[o].type,
				gridBy: obj[o].gridBy,
				oddBy: obj[o].oddBy
			};
			item.odds = item.odds.map((oddGroup) => oddGroup.filter((odd) => typeof odd !== "string"));
			arr.push(item);
		});
		return arr;
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {string} group - group name
	 * @param {number} gameType - Game type
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeName(str, gameData, argument, gameType, isTicket, isDefaultTranslation = false) {
		let result = str;
		const ns = isTicket ? "ticket:" : "";
		switch (true) {
			case str.includes("%Home%"):
				result = str.replace("%Home%", i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%Away%"):
				result = str.replace("%Away%", i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case str.includes("%[Home]%"):
				result = str.replace("%[Home]%", i18n.t(`markets.${gameType}.team`) + " 1");
				break;
			case str.includes("%[Away]%"):
				result = str.replace("%[Away]%", i18n.t(`markets.${gameType}.team`) + " 2");
				break;
			case str.includes("%Over%"):
				result = str.replace("%Over%", i18n.t(`markets.${gameType}.over`));
				break;
			case str.includes("%Under%"):
				result = str.replace("%Under%", i18n.t(`markets.${gameType}.under`));
				break;
			case ["Yes", "No"].includes(str):
				result = i18n.t(`common.${str.toLowerCase()}`);
				break;
			case ["Odd", "Even"].includes(str):
				result = i18n.t(`markets.${gameType}.${str}`);
				break;
			case str.includes("%HomeWinAfterOvertime%"):
				result = str.replace("%HomeWinAfterOvertime%", i18n.t(`markets.${gameType}.resultTeam1`));
				break;
			case str.includes("%AwayWinAfterOvertime%"):
				result = str.replace("%AwayWinAfterOvertime%", i18n.t(`markets.${gameType}.resultTeam2`));
				break;
			case ["OutOfGoals", "GoalSaves", "GoalWoodworks"].includes(str):
				result = i18n.t(`markets.${gameType}.${str}`);
				break;
			case isDefaultTranslation && [PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS].includes(str):
				result = i18n.t(`markets.${gameType}.${str}_title`);
				break;
			case isDefaultTranslation && [PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME], PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME]].includes(str):
				result = i18n.t(`markets.${gameType}.${str}`);
				break;
			case isDefaultTranslation && [PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME].includes(str):
				result = i18n
					.t(`markets.${gameType}.${PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[str]}`)
					.replace("%team1%", i18n.t(`markets.${gameType}.team`) + 1)
					.replace("%team2%", i18n.t(`markets.${gameType}.team`) + 2);
				break;
			default:
				if (isDefaultTranslation) {
					result = i18n.t(`markets.${gameType}.${str}`);
				}
				break;
		}

		if (!result) {
			return "bulk";
		}

		result = result.replace("Over", i18n.t(`${ns}markets.${gameType}.over`)).replace("Under", i18n.t(`${ns}markets.${gameType}.under`));
		return result;
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeWinnerMarketName(market) {
		let result = "";

		const countryCode1 = market?.gameData?.team1?.countryCode;
		const countryCode2 = market?.gameData?.team2?.countryCode; 

		const team1 = countryCode1 ? i18n.t(`countries.${market?.gameData?.team1?.countryCode}`) : "Team 1";
		const team2 = countryCode2 ? i18n.t(`countries.${market?.gameData?.team2?.countryCode}`) : "Team 2";

		switch (market.outcome) {
			case "Home":
				result = team1;
				break;
			case "Away":
				result = team2;
				break;
			case "Yes":
				result = i18n.t("common.yes");
				break;
			case "No":
				result = i18n.t("common.no");
				break;
			case "Over":
				result = i18n.t(`markets.${market.gameType}.over`);
				break;
			case "Under":
				result = i18n.t(`markets.${market.gameType}.under`);
				break;
			default:
				if ([PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE, PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT].includes(market.group)) {
					result = market.outcome.replace("Home", "").replace("Away", " : ");
				} else if (PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[market.group] || [PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME, PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME].includes(market.group)) {
					result = i18n.t(`markets.${market.gameType}.${market.outcome}`);
				} else if (market.outcome.includes("Goal")) {
					result = market.outcome.replace("Goal", "");
				} else {
					result = market.outcome;
				}
				break;
		}

		return result;
	}

	getArgument(market) {
		switch (true) {
			case market.argument !== null &&
				!isNaN(market.argument) &&
				([
					PENALTY_SHOOTOUT_MARKETS_GROUPS.BOTH_TEAMS_TO_SCORE,
					PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_GOAL_MISS,
					PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_GOAL_MISS,
					PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME,
					PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME,
					PENALTY_SHOOTOUT_MARKETS_GROUPS.CORRECT_SCORE_PER_SHOT,
					PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME],
					PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME]
				].includes(market.group) ||
					PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[market.group]):
				return i18n.t(`markets.${market.gameType}.R${market.argument}`);
			default:
				return market.argument;
		}
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeGroupTitle(market) {
		let res = "";
		const ns = market.isTicket ? "ticket:" : "";

		const gr = Object.values(PENALTY_MISS_CASE_MARKETS).includes(market.outcome) ? PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS[market.group] : market.group;
		res += i18n.t(`markets.${market.gameType}.${gr}`);
		res = res.replace("%team1%", "").replace("%team2%", "");

		if (![PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(market.group)) {
			const argument = this.getArgument(market);
			res += argument || argument === 0 ? " " + argument : "";
		}

		return res;
	}

	/** Make beuty text of bet
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof PenaltyShotoutsMarkets
	 */
	makeBetText(bet) {
		const team1 = bet?.gameData?.team1 ?? {};
		const team2 = bet?.gameData?.team2 ?? {};

		return (
			this.makeGroupTitle({
				period: bet.period,
				group: bet.group,
				team1: team1.countryCode ?? "Team 1",
				team2: team2.countryCode ?? "Team 2",
				argument: bet.argument,
				outcome: bet.outcome,
				gameType: bet.gameType
			}) +
			": " +
			this.makeOddText({
				outcome: bet.outcome,
				team1: team1.countryCode ?? "Team 1",
				team2: team2.countryCode ?? "Team 2",
				group: bet.group,
				argument: bet.argument,
				gameType: bet.gameType
			})
		);
	}

	/** Make beuty text of odd
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof PlayingSportMarkets
	 */
	makeOddText(bet) {
		return (
			this.makeWinnerMarketName({
				outcome: bet.outcome,
				gameData: bet?.gameData ?? {},
				group: bet.group,
				argument: bet.argument,
				gameType: bet.gameType
			}) + ([PENALTY_SHOOTOUT_MARKETS_GROUPS.HANDICAP, PENALTY_SHOOTOUT_MARKETS_GROUPS.ASIAN_HANDICAP].includes(bet.group) ? " (" + (bet.outcome === "Home" ? bet.argument : 0 - bet.argument) + ")" : "")
		);
	}
}

export default PenaltyShotoutsMarkets;
