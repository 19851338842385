import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { SET_REPRINT_LAST_TICKET_SHOW, SET_LAST_REPRINT_TICKET_BEFORE, SET_LAST_REPRINT_TICKET_AFTER, SET_LAST_REPRINT_TICKET } from "../../actionTypes";

import { printTicket } from "../tickets/ticket.actions";

import { TICKET_TYPE } from "constants/ticket.constants";

import runMarketUtilsFunction from "utils/markets/run";

export const setShowReprintLastTicket = (show) => ({
	type: SET_REPRINT_LAST_TICKET_SHOW,
	payload: { show }
});

const setLastReprintTicketBefore = () => ({
	type: SET_LAST_REPRINT_TICKET_BEFORE
});

const setLastReprintTicketAfter = () => ({
	type: SET_LAST_REPRINT_TICKET_AFTER
});

const setLastReprintTicket = (ticket) => ({
	type: SET_LAST_REPRINT_TICKET,
	payload: { ticket }
});

export const getLastReprintTicket = () => {
	return (dispatch) => {
		dispatch(setLastReprintTicketBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LAST_REPRINT_TICKET}`,
			method: Methods.GET
		})
			.then(({ data: { value: ticket } }) => {
				dispatch(setLastReprintTicket(ticket));
				dispatch(setLastReprintTicketAfter());
			})
			.catch((ex) => {
				dispatch(setLastReprintTicketAfter());
			});
	};
};

export const sendReprintLastTicketData = () => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REPRINT_LAST_TICKET}`,
			method: Methods.POST,
			data: {}
		})
			.then(({ data: { value: data } }) => {
				const ticket = getState().tickets.reprintLastTicket.ticket;

				/** Print Ticket */

				dispatch(setShowReprintLastTicket(false));
				if (ticket) {
					const p = {
						ticketType: TICKET_TYPE.BET,
						id: ticket.id,
						type: ticket.type,
						totalAmount: ticket.totalAmount,
						possibleWin: ticket.possibleWin,
						bets: ticket.bets
							.filter((b) => !b.expired)
							.map((b, ind) => ({
								...b,
								groupTitle: runMarketUtilsFunction("makeGroupTitle", [{ group: b.group, argument: b.argument, gameData: b.gameData, gameType: b.gameType, isTicket: true }], b.gameType),
								showName: runMarketUtilsFunction("makeName", [runMarketUtilsFunction("getNameForMarketGroup", [b.group, b.outcome, b.argument], b.gameType), b.gameData, b.argument, b.gameType, false], b.gameType),
								ticketShowName: runMarketUtilsFunction("makeName", [runMarketUtilsFunction("getNameForMarketGroup", [b.group, b.outcome, b.argument], b.gameType), b.gameData, b.argument, b.gameType, true], b.gameType),
								stake: b.amount,
								id: ticket.bets[ind].id
							})),
						additionalFactor: ticket.additionalFactor,
						bonusId: ticket.bonusId,
						bonusType: ticket.bonusType
					};
					dispatch(printTicket(p));
				}
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};
