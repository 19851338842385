import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

class PlayingSportMarkets extends BaseMarkets {
	constructor() {
		super();
		this.overUnderArgumentPriority = [2.5, 1.5, 3.5, 2, 1, 3, 0.5, 4, 4.5, 5, 5.5];
	}

	/** Get All possible correct score outcomes
	 * @function
	 * @returns {array}
	 * @memberof PlayingSportMarkets
	 */
	correctScoreOutcomes() {
		const arr = [];
		for (let i = 0; i < 7; i++) {
			for (let j = 0; j < 7; j++) {
				if (i + j < 7) {
					arr.push(`Home${i}Away${j}`);
				}
			}
		}
		return arr;
	}

	/** Get All possible market tabs with their markets
	 * @function
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	getGroupsStructure() {
		return {
			main: [
				{ group: "Winner3Way", title: "Winner3Way", outcomes: ["Home", "Draw", "Away"], marketsCount: 3, marketCol: 3, gridBy: "col", oddBy: "row", argument: null },
				{ group: "Total", title: "Total", outcomes: ["Over", "Under"], marketsCount: 2, marketCol: 4, gridBy: "col", oddBy: "row", argument: (arg) => [1.5, 2.5].includes(Number(arg)) },
				{ group: "WillBothTeamsScore", title: "WillBothTeamsScore", outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 2, gridBy: "col", oddBy: "row", argument: null },

				{ group: "HalfMatch", title: "HalfMatch", outcomes: ["P1P1", "P1H", "P1P2", "HP1", "HH", "HP2", "P2P2", "P2H", "P2P1"], marketsCount: 9, marketCol: 3, gridBy: "col", oddBy: "row", argument: null },

				{ group: "DoubleChance", title: "DoubleChance", outcomes: ["HomeOrDraw", "HomeOrAway", "AwayOrDraw"], marketsCount: 3, marketCol: 1, gridBy: "col", oddBy: "row", argument: null },

				{ group: "CorrectScore6Goals", title: "CorrectScore6Goals", outcomes: this.correctScoreOutcomes(), marketsCount: 28, marketCol: 7, gridBy: "col", oddBy: "row", argument: null },

				{ group: "Correct6GoalsCount", title: "Correct6GoalsCount", outcomes: Array.from({ length: 7 }).map((_, i) => `Goal${i}`), marketsCount: 7, marketCol: 1, gridBy: "col", oddBy: "row", argument: null }
			],
			other: [
				{ group: "Total", title: "Total", outcomes: ["Over", "Under"], marketsCount: 3, marketCol: 2, gridBy: "col", oddBy: "row", argument: (arg) => [1.5, 2.5, 3.5].includes(Number(arg)) },

				{ group: "HomeTotal", title: "HomeTotal", outcomes: ["Over", "Under"], marketsCount: 2, marketCol: 2, gridBy: "col", oddBy: "row", argument: (arg) => [1.5, 2.5].includes(Number(arg)) },

				{ group: "AwayTotal", title: "AwayTotal", outcomes: ["Over", "Under"], marketsCount: 2, marketCol: 2, gridBy: "col", oddBy: "row", argument: (arg) => [1.5, 2.5].includes(Number(arg)) },

				{ group: "Handicap", title: "Handicap", outcomes: ["Home", "Away"], marketsCount: 3, marketCol: 2, gridBy: "col", oddBy: "row", argument: (arg) => [0.5, 1.5, 2.5].includes(Number(arg)) },

				{ group: "Handicap", title: "AsianHandicap", outcomes: ["Home", "Away"], marketsCount: 3, marketCol: 2, gridBy: "col", oddBy: "row", argument: (arg) => [0.75, 1.75, 2.25].includes(Number(arg)) },

				{ group: "ResultWithoutDraw", title: "ResultWithoutDraw", outcomes: ["Home", "Away"], marketsCount: 2, marketCol: 1, gridBy: "col", oddBy: "row", argument: null },

				{ group: "WillHomeTeamScore", title: "WillHomeTeamScore", outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 1, gridBy: "col", oddBy: "row", argument: null },

				{ group: "WillAwayTeamScore", title: "WillAwayTeamScore", outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 1, gridBy: "col", oddBy: "row", argument: null },

				{ group: "YellowCard", title: "YellowCard", outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 1, gridBy: "col", oddBy: "row", argument: null },

				{ group: "RedCard", title: "RedCard", outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 1, gridBy: "col", oddBy: "row", argument: null },

				{ group: "Penalty", title: "Penalty", outcomes: ["Yes", "No"], marketsCount: 2, marketCol: 1, gridBy: "col", oddBy: "row", argument: null }
			]
		};
	}

	/** Get market name from systemName
	 * @function
	 * @param {array } marketName - the market name
	 * @param {object} gameData - Event data
	 * @param {number} argument - the market argument
	 * @returns {object}
	 * @memberof PlayingSportMarkets
	 */
	getNameForMarketGroup(marketName, outcome, argument) {
		if (["Total", "AsianTotal"].includes(marketName)) {
			return outcome + " (" + argument + ")";
		} else if (["HomeTotal", "AsianHomeTotal"].includes(marketName)) {
			return "%[Home]% " + outcome + " (" + argument + ")";
		} else if (["AwayTotal", "AsianAwayTotal"].includes(marketName)) {
			return "%[Away]% " + outcome + " (" + argument + ")";
		} else if (["Handicap", "AsianHandicap"].includes(marketName)) {
			return "%[" + outcome + "]% (" + (outcome === "Home" ? argument : 0 - argument) + ")";
		} else if (["Winner3Way", "DoubleChance", "HalfMatch", "WinnerAndTotal", "NextPoint", "ResultWithoutDraw", "Total3Way"].includes(marketName)) {
			return "%" + outcome + "%";
		} else if (["CorrectScore6Goals"].includes(marketName)) {
			return outcome.replace("Home", "").replace("Away", ":");
		} else if (["Correct6GoalsCount"].includes(marketName)) {
			return outcome.replace("Goal", "");
		} else if (["WillBothTeamsScore"].includes(marketName)) {
			return "bts" + outcome;
		} else {
			return outcome;
		}
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {object} gameData - Event data
	 * @param {number} argument - market argument
	 * @param {number} gameType - Current game type
	 * @returns {string}
	 * @memberof PlayingSportMarkets
	 */
	makeName(str, gameData, argument, gameType, isTicket, isDefaultTranslation = false) {
		let result = str;

		const ns = isTicket ? "ticket:" : "";

		switch (true) {
			case str.includes("%Home%"):
				result = "1"; //str.replace("%Home%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1");
				break;
			case str.includes("%Away%"):
				result = "2"; //str.replace("%Away%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2");
				break;
			case str.includes("%Draw%"):
				result = "X"; //str.replace("%Draw%", i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%[Draw]%"):
				result = str.replace("%[Draw]%", i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%[Home]%"):
				result = str.replace("%[Home]%", /* i18n.t(`${ns}markets.${gameType}.team`) + */ "1");
				break;
			case str.includes("%[Away]%"):
				result = str.replace("%[Away]%", /* i18n.t(`${ns}markets.${gameType}.team`) + */ "2");
				break;
			case str.includes("%Neither%"):
				result = str.replace("%Neither%", i18n.t(`${ns}markets.${gameType}.Neither`));
				break;
			case str.includes("%HomeOrDraw%"):
				result = "1-X"; //str.replace("%HomeOrDraw%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%HomeOrAway%"):
				result = "1-2"; //str.replace("%HomeOrAway%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2");
				break;
			case str.includes("%AwayOrDraw%"):
				result = "2-X"; //str.replace("%AwayOrDraw%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%P1P1%"):
				result = "1/1"; //str.replace("%P1P1%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + "/" + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1");
				break;
			case str.includes("%P1H%"):
				result = "1/X"; //str.replace("%P1H%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + "/" + i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%P1P2%"):
				result = "1/2"; //str.replace("%P1P2%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + "/" + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2");
				break;
			case str.includes("%HP1%"):
				result = "X/1"; //str.replace("%HP1%", i18n.t(`${ns}markets.${gameType}.Draw`) + "/" + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1");
				break;
			case str.includes("%HH%"):
				result = "X/X"; //str.replace("%HH%", i18n.t(`${ns}markets.${gameType}.Draw`) + "/" + i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%HP2%"):
				result = "X/2"; //str.replace("%HP2%", i18n.t(`${ns}markets.${gameType}.Draw`) + "/" + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2");
				break;
			case str.includes("%P2P2%"):
				result = "2/2"; //str.replace("%P2P2%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + "/" + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2");
				break;
			case str.includes("%P2H%"):
				result = "2/X"; //str.replace("%P2H%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + "/" + i18n.t(`${ns}markets.${gameType}.Draw`));
				break;
			case str.includes("%P2P1%"):
				result = "2/1"; //str.replace("%P2P1%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + "/" + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1");
				break;
			case str.includes("%Over%"):
				result = str.replace("%Over%", i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")");
				break;
			case str.includes("%Under%"):
				result = str.replace("%Under%", i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")");
				break;
			case str.includes("%Equal%"):
				result = str.replace("%Equal%", i18n.t(`${ns}markets.${gameType}.equal`) + "(" + argument + ")");
				break;
			case str.includes("%HomeAndUnder%"):
				result = str.replace("%HomeAndUnder%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")");
				break;
			case str.includes("%DrawAndUnder%"):
				result = str.replace("%DrawAndUnder%", i18n.t(`${ns}markets.${gameType}.Draw`) + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")");
				break;
			case str.includes("%AwayAndUnder%"):
				result = str.replace("%AwayAndUnder%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")");
				break;
			case str.includes("%HomeAndOver%"):
				result = str.replace("%HomeAndOver%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")");
				break;
			case str.includes("%DrawAndOver%"):
				result = str.replace("%DrawAndOver%", i18n.t(`${ns}markets.${gameType}.Draw`) + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")");
				break;
			case str.includes("%AwayAndOver%"):
				result = str.replace("%AwayAndOver%", i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")");
				break;
			case str.includes("AndMore"):
				result = str.replace("AndMore", " " + i18n.t(`${ns}markets.${gameType}.andMore`));
				break;
			case str.includes("%HomeOrDrawAndOver%"):
				result = str.replace(
					"%HomeOrDrawAndOver%",
					i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.Draw`) + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")"
				);
				break;
			case str.includes("%HomeOrAwayAndOver%"):
				result = str.replace(
					"%HomeOrAwayAndOver%",
					i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")"
				);
				break;
			case str.includes("%AwayOrDrawAndOver%"):
				result = str.replace(
					"%AwayOrDrawAndOver%",
					i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.Draw`) + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.over`) + "(" + argument + ")"
				);
				break;
			case str.includes("%HomeOrDrawAndUnder%"):
				result = str.replace(
					"%HomeOrDrawAndUnder%",
					i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.Draw`) + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")"
				);
				break;
			case str.includes("%HomeOrAwayAndUnder%"):
				result = str.replace(
					"%HomeOrAwayAndUnder%",
					i18n.t(`${ns}markets.${gameType}.resultTeam`) + "1" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")"
				);
				break;
			case str.includes("%AwayOrDrawAndUnder%"):
				result = str.replace(
					"%AwayOrDrawAndUnder%",
					i18n.t(`${ns}markets.${gameType}.resultTeam`) + "2" + " " + i18n.t(`${ns}markets.${gameType}.or`) + " " + i18n.t(`${ns}markets.${gameType}.Draw`) + " " + i18n.t(`${ns}markets.${gameType}.and`) + " " + i18n.t(`${ns}markets.${gameType}.under`) + "(" + argument + ")"
				);
				break;
			case ["Yes", "No"].includes(str):
				result = i18n.t(`${ns}common.${str.toLowerCase()}`);
				break;
			case ["btsYes", "btsNo"].includes(str):
				result = i18n.t(`${ns}markets.${gameType}.${str}`);
				break;
			case ["Odd", "Even"].includes(str):
				result = i18n.t(`${ns}markets.${gameType}.${str}`);
				break;
			default:
				if (isDefaultTranslation) {
					result = i18n.t(`markets.${gameType}.${str}`);
				}
				break;
		}

		result = result.replace("Over", i18n.t(`${ns}markets.${gameType}.over`)).replace("Under", i18n.t(`${ns}markets.${gameType}.under`));
		return result;
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof PlayingSportMarkets
	 */
	makeWinnerMarketName(market) {
		const ns = market.isTicket ? "ticket:" : "";

		const team1 = i18n.t(`${ns}countries.${market?.gameData?.team1?.countryCode ?? ""}`);
		const team2 = i18n.t(`${ns}countries.${market?.gameData?.team2?.countryCode ?? ""}`);
		switch (market.outcome) {
			case "Home":
				return team1;
			case "Away":
				return team2;
			case "Draw":
				return i18n.t(`${ns}markets.${market.gameType}.Draw`);
			case "Neither":
				return i18n.t(`${ns}markets.${market.gameType}.Neither`);
			case "HomeOrDraw":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + i18n.t(`${ns}markets.${market.gameType}.Draw`);
			case "HomeOrAway":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + team2;
			case "AwayOrDraw":
				return team2 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + i18n.t(`${ns}markets.${market.gameType}.Draw`);
			case "HomeAndUnder":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.under`);
			case "HomeAndOver":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.over`);
			case "DrawAndUnder":
				return i18n.t(`${ns}markets.${market.gameType}.Draw`) + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.under`);
			case "DrawAndOver":
				return i18n.t(`${ns}markets.${market.gameType}.Draw`) + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.over`);
			case "AwayAndUnder":
				return team2 + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.under`);
			case "AwayAndOver":
				return team2 + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.over`);
			case "HomeOrDrawAndOver":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + i18n.t(`${ns}markets.${market.gameType}.Draw`) + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.over`);
			case "HomeOrAwayAndOver":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + team2 + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.over`);
			case "AwayOrDrawAndOver":
				return team2 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + i18n.t(`${ns}markets.${market.gameType}.Draw`) + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.over`);
			case "HomeOrDrawAndUnder":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + i18n.t(`${ns}markets.${market.gameType}.Draw`) + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.under`);
			case "HomeOrAwayAndUnder":
				return team1 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + team2 + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.under`);
			case "AwayOrDrawAndUnder":
				return team2 + " " + i18n.t(`${ns}markets.${market.gameType}.or`) + " " + i18n.t(`${ns}markets.${market.gameType}.Draw`) + " " + i18n.t(`${ns}markets.${market.gameType}.and`) + " " + i18n.t(`${ns}markets.${market.gameType}.under`);
			default:
				if (market.group === "CorrectScore6Goals") return market.outcome.replace("Home", "").replace("Away", " : ");
				if (["Correct6HomeGoalsCount", "Correct6AwayGoalsCount", "Correct6GoalsCount"].includes(market.group)) return market.outcome.replace("Goal", "").replace("AndMore", " " + i18n.t(`${ns}markets.${market.gameType}.andMore`));
				if (market.group === "HalfMatch")
					return market.outcome
						.replace("P1P1", team1 + "/" + team1)
						.replace("P1H", team1 + "/" + i18n.t(`${ns}markets.${market.gameType}.Draw`))
						.replace("P1P2", team1 + "/" + team2)
						.replace("HP1", i18n.t(`${ns}markets.${market.gameType}.Draw`) + "/" + team1)
						.replace("HH", i18n.t(`${ns}markets.${market.gameType}.Draw`) + "/" + i18n.t(`${ns}markets.${market.gameType}.Draw`))
						.replace("HP2", i18n.t(`${ns}markets.${market.gameType}.Draw`) + "/" + team2)
						.replace("P2P2", team2 + "/" + team2)
						.replace("P2H", team2 + "/" + i18n.t(`${ns}markets.${market.gameType}.Draw`))
						.replace("P2P1", team2 + "/" + team1);
				return market.outcome;
		}
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof PlayingSportMarkets
	 */
	makeGroupTitle(market) {
		let res = "";
		const ns = market.isTicket ? "ticket:" : "";

		res += i18n.t(`${ns}markets.${market.gameType}.${market.group}`);

		res = res.replace("%team1%", i18n.t(`${ns}markets.${market.gameType}.team`) + "1").replace("%team2%", i18n.t(`${ns}markets.${market.gameType}.team`) + "2");
		return res;
	}

	/** Make beuty text of bet
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof PlayingSportMarkets
	 */
	makeBetText(bet) {
		return this.makeGroupTitle({ group: bet.group, gameData: bet?.gameData ?? {}, argument: bet.argument, gameType: bet.gameType }) + ": " + this.makeOddText(bet);
	}

	/** Make beuty text of odd
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof PlayingSportMarkets
	 */
	makeOddText(bet) {
		return this.makeWinnerMarketName({ outcome: bet.outcome, gameData: bet?.gameData ?? {}, group: bet.group, gameType: bet.gameType }) + (["Total", "HomeTotal", "AwayTotal", "Handicap", "WinnerAndTotal", "Total3Way"].includes(bet.group) ? " (" + bet.argument + ")" : "");
	}
}

export default PlayingSportMarkets;
