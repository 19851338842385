import moment from "moment";

import { BETSLIP_MODES } from "constants/betslip.constants";
import { TURNOVER_TYPE } from "constants/common.constants";
import SessionStorageUtils from "utils/sessionStorage";
import { getDefaultDate } from "utils/dateTime";

export default {
	common: {
		alertMessages: [],
		errorMessage: "",
		currentTime: moment().unix()
	},
	auth: {
		sessionLoaded: false,
		player: SessionStorageUtils.get("authorizationData") || {},
		sessionId: null,
		sessionFailed: false,
		noGameFound: false,
		refreshTokenExpiration: -1,
		isLoginLoading: false,
		activation: {
			isActivating: false
		},
		resetPassword: {
			isReseting: false
		},
		forgotPassword: {
			isSaving: false
		},
		passwordSettings: {
			isLoading: false,
			passwordSettings: {}
		},
		changePassword: {
			isPasswordChanging: false,
			isLoading: false,
			passwordSettings: {}
		},
		session: {
			hotKeys: [],
			games: []
		},
		stream: {
			rtps: []
		},
		translationsLoaded: false,
		isLanguagesLoading: false,
		languagesLoaded: false,
		languages: []
	},
	game: {
		currentGameType: null,
		liveAndUpcomings: {
			loading: false,
			data: []
		},
		lastResults: {
			loading: false,
			data: []
		},
		matches: {
			loading: false,
			data: []
		},
		current: null
	},
	season: {
		special: {
			markets: [],
			seasonId: null,
			status: null
		},
		stendings: [],
		teamsHeadToHead: {}
	},
	betslip: {
		mode: BETSLIP_MODES.SINGLE,
		stake: "",
		bets: [],
		loading: false,
		forecast: null,
		kenoBalls: {
			balls: []
		},
		showPaymentMethod: false,
		confirmingTransaction: null,
		focusStake: {
			betIdentifiers: null,
			timeoutId: null
		}
	},
	tickets: {
		isPendingsLoading: false,
		pendings: [],
		isSettledLoading: false,
		settled: [],
		settledTotal: 0,
		filters: {
			limit: 20,
			page: 1,
			betSlipId: "",
			eventId: "",
			period: "24_hours",
			...getDefaultDate()
		},
		confirmation: {
			isLoading: false,
			type: null,
			ticketId: null,
			amount: null,
			view: false,
			jackpotLevelType: null
		},
		isTicketLoading: false,
		viewingTicket: null,
		showTicket: false,
		printingTicket: null,
		reprintLastTicket: {
			show: false,
			isLoading: false,
			ticket: null
		}
	},
	bonuses: {
		standard: {
			isBonusLoading: false,
			viewingBonus: null,
			showBonus: false,
			useBonus: false,
			bonus: null
		},
		jackpot: {
			isLoading: false,
			data: {},
			wonJackpots: []
		}
	},
	report: {
		isLoading: false,
		turnover: {
			report: {},
			betSlipCount: 0,
			wonCount: 0,
			playedCount: 0,
			balance: 0,
			canceled: 0,
			ggr: 0,
			netTurnover: 0,
			payout: 0,
			turnover: 0,
			won: 0,
			wonUnpaid: 0
		},
		printingReport: null,
		type: TURNOVER_TYPE.SIMPLE
	},
	results: {
		results: [],
		isLoading: false,
		total: 0,
		filters: {
			limit: 20,
			page: 1,
			type: "",
			eventId: "",
			period: "24_hours",
			...getDefaultDate()
		},
		resultsToPrint: [],
		resultsToPrintIsLoading: false,
		printingResults: false
	},
	qr: {
		isLoading: false,
		ticket: {}
	},
	templates: {
		isVisible: false
	}
};
