import  { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalWrapper from "components/modalWrapper";
import ConfirmModal from "components/ui/confirm";
import TurnoverReport from "./turnover";
import { setReportPrinting, getTurnoverReport, setTurnoverType } from "store/actions/report/report.actions";
import { REPORT_TYPE, TURNOVER_TYPE } from "constants/common.constants";

/** Reports Modal Component */
const Reports = ({ coordinates, onCancel, showReportPrinting, setSettlementReport, visible, getTurnoverReport, setTurnoverType, isLoading }) => {
	const { t } = useTranslation();

	/** State to show/hide confirmation modal */
	const [showConfirm, setShowConfirm] = useState(false);

	/** Load Turnover Report */
	useEffect(() => {
		visible && getTurnoverReport();
	}, [visible]);

	/** Function, will fire on settlement button click
	 * @function
	 * @memberOf Reports
	 */
	const handleSettlementClick = () => {
		onCancel();
		setShowConfirm(true);
	};

	/** Function, will fire on settlement confirmation modal button click
	 * @function
	 * @memberOf Reports
	 */
	const handleSettlementConfirm = () => {
		setShowConfirm(false);
		showReportPrinting(REPORT_TYPE.SETTLEMENT);
		setSettlementReport();
	};

	const subTabs = [
		{
			key: TURNOVER_TYPE.SIMPLE,
			element: t("cashier.turnover")
		},
		{
			key: TURNOVER_TYPE.BONUS,
			element: t("bonus.bonus") + " " + t("cashier.turnover")
		}
	];

	return (
		<Fragment>
			<ModalWrapper
				coordinates={coordinates}
				title={t("cashier.reports")}
				subTabs={subTabs}
				classNames={{ content: "vs--modal vs--report-modal" }}
				onSubTabClick={(key) => setTurnoverType(key)}
				onCancel={onCancel}
				visible={visible}
				footer={
					<>
						<button disabled={isLoading} type="button" className="vs--button vs--button-interim-report vs--pl-16 vs--pr-16 vs--pt-10 vs--pb-10 vs--font-small" onClick={() => showReportPrinting(REPORT_TYPE.INTERIM)}>
							{t("cashier.interimReport")}
						</button>
						<button disabled={isLoading} type="button" className="vs--button vs--button-settlement-report vs--pl-16 vs--pr-16 vs--pt-10 vs--pb-10 vs--font-small vs--ml-8" onClick={handleSettlementClick}>
							{t("cashier.settlement")}
						</button>
					</>
				}
			>
				{visible && <TurnoverReport />}
			</ModalWrapper>
			<ConfirmModal
				visible={showConfirm}
				width="335px"
				onCancel={() => setShowConfirm(false)}
				onConfirm={handleSettlementConfirm}
				isLoading={false}
				success={false}
				title={t("cashier.settlementReportConfirmMessage")}
				subTitle={t("cashier.settlementReportConfirmMessageDescription")}
				extraButton={null}
				confirmText={t("cashier.print")}
				declineText={t("common.cancel")}
			/>
		</Fragment>
	);
};

/** Reports propTypes
 * PropTypes
 */
Reports.propTypes = {
	/** Css variables` viewport x, y coordinates */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** Function that will be called on Reports modal close */
	onCancel: PropTypes.func,
	/** Redux action to show report print component*/
	showReportPrinting: PropTypes.func,
	/** If true the modal will be visible*/
	visible: PropTypes.bool,
	/** Redux action to set settlement report */
	setSettlementReport: PropTypes.func,
	/** Redux action to get turnover report */
	getTurnoverReport: PropTypes.func,
	/** Redux state property, turnover type */
	type: PropTypes.number,
	/** Redux action to get turnover type */
	setTurnoverType: PropTypes.func,
	/** Redux state property, is true when loading turnover report */
	isLoading: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		type: state.report.type,
		isLoading: state.report.isLoading
	};
};

const mapDispatchToProps = (dispatch) => ({
	showReportPrinting: (type) => {
		dispatch(setReportPrinting(type));
	},
	setSettlementReport: () => {
		dispatch(getTurnoverReport(true));
	},
	getTurnoverReport: () => {
		dispatch(getTurnoverReport());
	},
	setTurnoverType: (type) => {
		dispatch(setTurnoverType(type));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
