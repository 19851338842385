import i18n from "translations/config";

import BaseMarkets from "./baseMarkets";

import { GAME_TYPE } from "constants/game.constants";

class KenoMarkets extends BaseMarkets {
	constructor() {
		super();
	}

	/** Add teams name into market name
	 * @function
	 * @param {string } str - market name
	 * @param {object} gameData - Game Data
	 * @param {number} gameType - Current game type
	 * @returns {string}
	 * @memberof KenoMarkets
	 */
	makeName(str, gameData, argument, gameType, isTicket) {
		return str.split(":").join(", ");
	}

	/** Get market name from systemName
	 * @function
	 * @param {array } marketName - the market name
	 * @param {object} outcome - Event data
	 * @param {number} argument - the market argument
	 * @returns {object}
	 * @memberof KenoMarkets
	 */
	getNameForMarketGroup(marketName, outcome, argument) {
		return outcome;
	}

	/** Make market beuty name, to show in winner market section
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof KenoMarkets
	 */
	makeWinnerMarketName(market) {
		return this.makeName(market.outcome, market.gameData, null, market.gameType);
	}

	/** Make market beuty name from system name
	 * @function
	 * @param {object} market - the market object
	 * @returns {string}
	 * @memberof KenoMarkets
	 */
	makeGroupTitle(market) {
		return i18n.t("common.selectedNumbers");
	}

	/** Make beuty text of bet
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof KenoMarkets
	 */
	makeBetText(bet) {
		return this.makeGroupTitle({ group: bet.group, gameType: bet.gameType }) + ": " + this.makeOddText(bet);
	}

	/** Make beuty text of odd
	 * @function
	 * @param {object} bet - bet
	 * @returns {string}
	 * @memberof KenoMarkets
	 */
	makeOddText(bet) {
		return this.makeName(bet.outcome, bet.gameData, null, bet.gameType);
	}
}

export default KenoMarkets;
