import  { Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import MarketOdd from "./marketOdd";

import { numberWithSpaces, isRacingGame, isSeasonGame } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";

import { GAME_TYPE, GAME_STATUSES, GAME_ACTIVITY_STATE } from "constants/game.constants";

import { doBet } from "store/actions/betslip/betslip.actions";

import eventType from "types/event.type";
import betType from "types/bet.type";
import sessionType from "types/session.type";

/** Regular Markets list component */
const RegularMarkets = ({ eventInfo, doBet, bets, currentGameType, markets, session, activeTab }) => {
	/** Function to check if bet is already in betslip bets
	 * @function
	 * @param {string} id
	 * @returns {boolean}
	 * @memberOf RegularMarkets
	 */
	const isOddSelected = (id) => {
		return bets.some((b) => b.oddId === id && b.eventId === eventInfo.id);
	};

	/** Function to check if odd is disabled
	 * @function
	 * @param {object} id
	 * @returns {boolean}
	 * @memberOf RegularMarkets
	 */
	const isOddDisabled = (odd) => {
		const game = session.games.find((g) => g.type === currentGameType);

		return (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfo?.status) ||
			!odd.factor ||
			odd.factor < 1.01
		);
	};

	/** Function which fires on market click
	 * @function
	 * @description adds bet to betslip bets list
	 * @param {object} odd - odd info
	 * @param {object} market - market info
	 * @memberOf RegularMarkets
	 */
	const handleOddClick = (odd, market) => {
		if (isOddDisabled(odd)) {
			return;
		}

		const group = runMarketUtilsFunction(
			"makeGroupTitle",
			[
				{
					group: market.group,
					argument: market.argument,
					gameData: eventInfo?.gameData ?? {},
					gameType: currentGameType
				}
			],
			currentGameType
		);

		doBet(odd, group, eventInfo);
	};

	const groupedMarkets = useMemo(() => {
		// Grouping markets
		const _results = markets.reduce((acc, market) => {
			let { odds, ...other } = market;

			if (!odds.length) {
				odds = Array(Math.max(market.marketCol, market.marketsCount)).fill({});
			}
			const newMarket = odds.flat().map((odd) => ({ ...odd, ...other }));

			if (activeTab === "main" && ["Winner3Way", "Total", "WillBothTeamsScore"].includes(market.group)) {
				if (acc["mainMarkets"]) {
					acc["mainMarkets"].markets.push([null]);
					acc["mainMarkets"].markets.push(newMarket);

					if (acc["mainMarkets"].marketCol < market.marketCol) {
						acc["mainMarkets"].marketCol = market.marketCol;
					}
				} else {
					acc["mainMarkets"] = {
						title: runMarketUtilsFunction("makeName", ["mainMarkets", market.argument, eventInfo?.gameData ?? {}, currentGameType, false, true], currentGameType),
						name: "mainMarkets",
						markets: [newMarket],
						marketCol: market.marketCol,
						needDecrement: false,
						gridBy: market.gridBy,
						oddBy: market.oddBy
					};
				}
			} else {
				const additionalStr = ["HomeTotal", "AwayTotal", "WillHomeTeamScore", "WillAwayTeamScore"].includes(market.group) ? "Cashier" : "";

				acc[market.group] = {
					title: runMarketUtilsFunction("makeName", [`${additionalStr}${market.group}`, market.argument, eventInfo?.gameData ?? {}, currentGameType, false, true], currentGameType),
					name: market.group,
					markets: [newMarket],
					marketCol: market.marketCol,
					needDecrement: Boolean(["CorrectScore6Goals"].includes(market.group)),
					gridBy: market.gridBy,
					oddBy: market.oddBy
				};
			}

			return acc;
		}, {});

		return Object.values(_results).reduce((acc, res) => {
			if (res.name === "Handicap") {
				acc.push(null);
			}

			res.markets = res.markets.flat();
			acc.push(res);

			if (res.name === "AsianHandicap") {
				acc.push(null);
			}

			return acc;
		}, []);
	}, [markets, activeTab]);

	const renderMarkets = (marketsArray = [], mainFlexDirection = "row", maxColCountInRow = 1) => {
		let index = 0;

		const allTags = marketsArray.reduce((...args) => {
			const [blocks, marketsInfo, , mainArr] = args;

			// Create Array for odds container
			if (index % maxColCountInRow === 0 || !marketsInfo) {
				blocks.push([]);

				if (!marketsInfo) {
					index = 0;
					return blocks;
				}
			}

			// Fill Array with odds container
			const marketContainer = [];

			if (!marketsInfo.marketCol) {
				marketContainer.push([]);
			} /* only for racing */

			if (!marketsInfo.needDecrement) {
				let matrixIndex = 0;

				for (let i = 0; i < marketsInfo.markets.length; i++) {
					const odd = marketsInfo.markets[i];

					if (odd && matrixIndex % marketsInfo.marketCol === 0) {
						marketContainer.push([]);
					}

					if (!odd) {
						marketContainer.push([]);
						matrixIndex = 0;
					} else {
						marketContainer[marketContainer.length - 1].push(odd);
						matrixIndex++;
					}
				}
			} else {
				let matrixIndex = 0;

				for (let i = 0; i < marketsInfo.marketCol; i++) {
					marketContainer.push([]);

					for (let j = 0; j < marketsInfo.marketCol - i; j++) {
						const odd = marketsInfo.markets[matrixIndex++];
						marketContainer[marketContainer.length - 1].push(odd);
					}
				}
			}

			blocks[blocks.length - 1].push(
				<Fragment key={marketsInfo.name}>
					<div className="vs--markets-list-item" data-market={marketsInfo.name}>
						<div className="vs--markets-list-item-inner">
							<div className="vs--markets-list-item-header vs--mb-10">
								<span className="vs--title vs--font-exstrasmall vs--font-medium vs--font-condensed">{marketsInfo.title}</span>
							</div>
							<div className={`vs--markets-list-item-body vs--flex vs--flex-${marketsInfo.gridBy}`}>
								{marketContainer.map((marketRow, i) => {
									if (!marketRow.length) {
										return <Fragment key={i} />;
									}

									return (
										<div key={i} className={`vs--flex vs--flex-${marketsInfo.oddBy}`}>
											{marketRow.map((odd, j) => (
												<MarketOdd key={`${i}:${j}`} odd={odd} isOddDisabled={isOddDisabled} isOddSelected={isOddSelected} handleOddClick={handleOddClick} numberWithSpaces={numberWithSpaces} />
											))}
										</div>
									);
								})}
							</div>
						</div>
					</div>

					{mainFlexDirection === "row" && !((index + 1) % maxColCountInRow === 0 || mainArr.length === index + 1) ? <div className="vs--markets-border-regular" /> : null}
				</Fragment>
			);
			index++;
			return blocks;
		}, []);

		return (
			<div className={`vs--markets-list vs--pt-16 ${mainFlexDirection === "row" ? "vs--pb-36" : "vs--pb-36 vs--flex vs--flex-row"}`} data-market-tab={activeTab} data-market-game={currentGameType}>
				<div className={"vs--event-other"}>
					{allTags.map((tag, i) => (
						<Fragment key={i}>
							{mainFlexDirection === "col" && !(i === 0) && tag.length > 0 ? <div className="vs--markets-border-regular" /> : null}
							{tag.length ? <div className={`vs--flex vs--flex-${mainFlexDirection} vs--markets-main-row-content`}>{tag}</div> : null}
						</Fragment>
					))}
				</div>
			</div>
		);
	};

	if (!(isRacingGame(currentGameType) && activeTab !== "main") && !(GAME_TYPE.FOOTBALL_SINGLE_MATCH === currentGameType || isSeasonGame(currentGameType))) {
		return null;
	}

	return renderMarkets(groupedMarkets, GAME_TYPE.FOOTBALL_SINGLE_MATCH === currentGameType && activeTab !== "main" ? "col" : "row", GAME_TYPE.FOOTBALL_SINGLE_MATCH === currentGameType ? 3 : 1);
};

/** RegularMarkets propTypes
 * PropTypes
 */
RegularMarkets.propTypes = {
	/** Current event info */
	eventInfo: eventType,
	/** Redux action to add bet into betslip bets */
	doBet: PropTypes.func,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE)),
	/** Array of markets to show */
	markets: PropTypes.array,
	/** Redux state property, current session */
	session: sessionType,
	/** Active tab */
	activeTab: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		bets: state.betslip.bets,
		currentGameType: state.game.currentGameType,
		session: state.auth.session
	};
};

const mapDispatchToProps = (dispatch) => ({
	doBet: (market, group, eventInfo) => {
		dispatch(doBet(market, group, eventInfo));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RegularMarkets);
