export const PERIOD = {
	MATCH: 0,
	FIRSTHALF: 1,
	SECONDHALF: 2,
	NONE: 3,
	OVERTIME: 4,
	PENALTY_SHOOTOUT: 5
}

export const ODD_STATE = {
	PENDING: 0,
	WON: 1,
	LOST: 2,
	SEMIWON: 3,
	RETURN: 4,
	SEMILOST: 5
}

export const PENALTY_SHOOTOUT_MARKETS_TABS = {
	// ALL: "all",
	MAIN: "main",
	CORRECT_SCORE: "correctScore",
	// TOTAL: "total",
	// HANDICAP: "handicap",
	PENALTY_SHOT_OUTCOME: "penaltyShotOutcome"
}

export const PENALTY_SHOOTOUT_MARKETS_GROUPS = {
	RESULT: "Winner2Way",
	BOTH_TEAMS_TO_SCORE: "BothTeamsToScore",
	HOME_GOAL_MISS: "HomeGoalMiss",
	AWAY_GOAL_MISS: "AwayGoalMiss",
	HOME_SHOT_OUTCOME: "HomePenaltyShot",
	AWAY_SHOT_OUTCOME: "AwayPenaltyShot",
	SUDDEN_DEATH_OUTCOME: "MatchEndsInOvertime",
	SUDDEN_DEATH_RESULT: "MethodOfVictoryOvertime",
	CORRECT_SCORE: "CorrectScore",
	CORRECT_SCORE_PER_SHOT: "CorrectScorePerShot",
	HANDICAP: "Handicap",
	ASIAN_HANDICAP: "AsianHandicap",
	TOTAL_GOALS: "TotalGoals",
	TOTAL_PENALTIES: "TotalRealizePenalty",
	TOTAL_MISS: "TotalMissed",
	TOTAL_GOALS_ASIAN: "AsianTotal",
	MATCH_GOALS_COUNT: "MatchGoalsCount"
}

export const PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS = {
	PENALTY_SHOT_OUTCOME: "PenaltyShotOutcome",
	[PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME]: `${PENALTY_SHOOTOUT_MARKETS_GROUPS.HOME_SHOT_OUTCOME}_MissCase`,
	[PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME]: `${PENALTY_SHOOTOUT_MARKETS_GROUPS.AWAY_SHOT_OUTCOME}_MissCase`
}

export const PENALTY_MISS_CASE_MARKETS = {
	OUT_OF_GOALS: "OutOfGoals",
	GOAL_SAVES: "GoalSaves",
	GOAL_WOODWORKS: "GoalWoodworks"
}
